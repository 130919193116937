import { formsQuery } from '@se/data/forms/query/index.ts';
import { clearDateFilter } from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import {
  initializePermissions,
  initialized,
  initializing,
  returnHome,
} from '@seeeverything/ui.shell/src/redux/app/actions.ts';
import { reinitialize } from '@seeeverything/ui.shell/src/redux/csvExport/actions.ts';
import { next } from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import {
  QueryBuilderModuleChangedFromSheetAction,
  QueryBuilderModuleChangedFromUrlAction,
} from '@seeeverything/ui.shell/src/redux/query/types.ts';
import { clear } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetChangeModuleAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import {
  TenantConfiguration,
  TenantLocale,
  tenantSlice,
} from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map, of, switchMap } from 'rxjs';
import {
  getInitializedApp,
  isAppInitialized,
  setAppInitialized,
} from '../../../app.ts';
import { db } from '../../../common/index.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';
import { init as queryBuilderInit } from '../../config.queryBuilder/index.ts';
import { updatedModule } from './actions.ts';
import { IUpdatedModule } from './types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  resetDashboardDateFiltersOnModuleChanged,
  updatedModuleEpic,
  updateModuleFromSheetOrUrlEpic,
);

function updateModuleFromSheetOrUrlEpic(
  action$: Observable<
    | QueryBuilderModuleChangedFromSheetAction
    | QueryBuilderModuleChangedFromUrlAction
  >,
) {
  return action$.pipe(
    ofType(
      'ui.shell/query/MODULE_CHANGED_FROM_SHEET',
      'ui.shell/query/MODULE_CHANGED_FROM_URL',
    ),
    switchMap(async ({ payload: { query, module, source } }) => {
      storageApi.module.set(module);
      await changeAppModule(module);

      const allowedTypes = ['bu', 'people', 'team'];
      const newQuery = {
        ...query,
        chips: query.chips.filter((chip) =>
          allowedTypes.some((allowed) => chip.type === allowed),
        ),
      };

      const updateAction = updatedModule(module, newQuery, source);

      return newQuery.chips.length > 0
        ? of(updateAction)
        : from([returnHome()]);
    }),
    concatAll(),
  );
}

function updatedModuleEpic(action$: Observable<IUpdatedModule>) {
  return action$.pipe(
    ofType('app/queryBuilder/UPDATED_MODULE'),
    map(({ payload }) => {
      // CLEAR / NEXT is used to refresh any currently opened sheets.
      return from([clear(), next('CODE', payload.query, undefined, true)]);
    }),
    concatAll(),
  );
}

function resetDashboardDateFiltersOnModuleChanged(
  action$: Observable<SheetChangeModuleAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/CHANGE_MODULE'),
    filter(({ payload }) => payload.from !== payload.to),
    map(clearDateFilter),
  );
}

/**
 * Changes the context of the UI application to use the newly provided module value.
 * This effectively re-initializes the application.
 */
export const changeAppModule = async (module: ModuleType) => {
  if (!isAppInitialized()) {
    return;
  }

  const app = getInitializedApp();
  const dispatch = app.store.dispatch;

  dispatch(initializing());

  dispatch(tenantSlice.setModule({ to: module }));
  const tenantConfig = app.store.getState().tenantState.tenant.configuration;
  const locale = app.store.getState().tenantState.tenant.locale;
  const tenantFeatures = app.store.getState().tenantState.tenant.features;

  const formPermissionsResponse = await formsQuery.getFormPermissions(
    db.client,
    { module, tenantFeatures },
  );

  const formPermissions = formPermissionsResponse.isSuccess
    ? formPermissionsResponse.data
    : {
        scheduleManage: false,
        templateManage: false,
        instanceCreate: false,
        bulkFormLoadManage: false,
        automatedActionManage: false,
      };

  const updatedApp = await reinitializeQueryBuilder(tenantConfig, locale);

  dispatch(reinitialize());

  setAppInitialized(updatedApp); // Make sure this new app is now known to the app provider.

  dispatch(initializePermissions(formPermissions));
  dispatch(initialized());

  // Signal to subscribers to take appropriate action of app change.
  updatedApp?.updatedApp$?.next(updatedApp);

  return updatedApp;
};

/**
 * Re-creates the Query Builder, based on new configurations post a module change.
 */
const reinitializeQueryBuilder = async (
  tenantConfig: TenantConfiguration,
  locale: TenantLocale,
) => {
  if (!isAppInitialized()) return undefined;

  return {
    ...getInitializedApp(),
    queryBuilder: await queryBuilderInit(tenantConfig, locale),
  };
};
