/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export const ActionAuditSkeleton: React.FC = () => {
  return (
    <>
      <ActionAuditSkeletonEntry />
      <ActionAuditSkeletonEntry />
      <ActionAuditSkeletonEntry />
      <ActionAuditSkeletonEntry />
    </>
  );
};

const ActionAuditSkeletonEntry: React.FC = () => {
  return (
    <div css={styles.base}>
      <Skeleton height={30} width={20} />
      <div css={styles.textSkeleton}>
        <Skeleton width={400} />
        <Skeleton height={12} width={100} />
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    gap: 20,
    padding: 20,
    margin: -10,
    ':hover': {
      backgroundColor: color.format(-0.04),
    },
  }),
  textSkeleton: css({
    flexDirection: 'column',
    flex: '1 1 auto',
  }),
};
