import * as R from 'ramda';
import { IChartValue } from './Chart/types.ts';
import { IBarProps, ILineProps } from './types.ts';

const isDataKeyUsed = (
  datapoint: any,
  bars?: IBarProps[],
  lines?: ILineProps[],
) => {
  // startsWith() allows chart components to consider data that has depth from its keys e.g. 'a.b' in the data is { a: { b: } } but the check is only considering 'a'.
  if (
    bars &&
    bars.some((bar) => bar.dataKey.toString().startsWith(datapoint))
  ) {
    return true;
  }

  if (
    lines &&
    lines.some(
      (line) =>
        line.dataKey.toString().startsWith(datapoint) && !line.dataIsPercentage,
    )
  ) {
    return true;
  }
};

export const getMaximumDataValue = (
  data: IChartValue[],
  bars?: IBarProps[],
  lines?: ILineProps[],
) => {
  return data.reduce((accAll, singleGroup) => {
    const max = Object.keys(singleGroup).reduce(
      (accSingle, singleDatapoint) => {
        const datapoint = singleGroup[singleDatapoint];
        const dataAsNumber = parseFloat(datapoint as string);
        if (
          R.is(Number, dataAsNumber) &&
          !isNaN(dataAsNumber) &&
          isDataKeyUsed(singleDatapoint, bars, lines)
        ) {
          return R.max(accSingle, dataAsNumber);
        } else if (
          isDataKeyUsed(singleDatapoint, bars, lines) &&
          R.is(Object, datapoint)
        ) {
          const maxInObject = R.reduce(
            R.max,
            -Infinity,
            R.values(datapoint as object),
          ) as number;
          return R.max(accSingle, maxInObject);
        }

        return accSingle;
      },
      0,
    );

    return R.max(accAll, max);
  }, 0);
};

export const hasDataForKey = (key: string | number, data: any[]) => {
  return data && data.some((x) => fromDepthKey(key, x) !== undefined);
};

export const adjustedLineDataForPercentage = (
  allData: IChartValue[],
  dataPoint: IChartValue,
  maxDataSize: number,
  lines?: ILineProps[],
) => {
  if (!lines) {
    return;
  }

  return lines
    .map((line) => {
      const lineDataIsPercentage = Boolean(
        line.dataIsPercentage && hasDataForKey(line.dataKey as string, allData),
      );

      if (!lineDataIsPercentage) {
        return;
      }

      const lineDataKey = lineDataIsPercentage
        ? (line.dataKey as string)
        : undefined;

      const unmodifiedLine =
        lineDataKey && fromDepthKey<string>(lineDataKey, dataPoint);

      const parsed = unmodifiedLine ? parseFloat(unmodifiedLine) : undefined;
      return lineDataKey
        ? {
            [lineDataKey]:
              parsed !== undefined && R.is(Number, parsed) && !isNaN(parsed)
                ? (parsed / 100) * maxDataSize
                : unmodifiedLine,
            [`${lineDataKey}-original`]: unmodifiedLine,
            [`${lineDataKey}-formatAsPercentage`]: true,
          }
        : undefined;
    })
    .reduce((a, v) => ({ ...a, ...v }), {});
};

/**
 * Uses a key that has been separated with '.' to get a value with depth in an object.
 *
 * @example
 * const object = { a: { b: 4 } };
 *
 * fromDepthKey('a.b', object) // 4
 */
const fromDepthKey = <T>(key: string | number, object: object): T =>
  R.path(key.toString().split('.'), object) as T;
