/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Chip } from '@seeeverything/ui.primitives/src/components/Chip/Chip.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useEffect, useMemo } from 'react';
import { useEmitDebounce } from '../../hooks/useEmitDebounce.ts';
import { useViewport } from '../../hooks/useViewport.ts';
import {
  CheckboxGroup,
  CheckboxGroupEvent,
} from '../CheckboxRadioGroup/CheckboxGroup.tsx';
import { ItemLabelValues } from '../CheckboxRadioGroup/types.ts';
import { Icons } from '../Icon/Icons.tsx';
import { InputAdornment } from '../InputAdornment/InputAdornment.tsx';
import { SelectionListItem } from '../SelectionListItem/SelectionListItem.tsx';
import Spinner from '../Spinner/Spinner.tsx';
import { TextField } from '../TextField/TextField.tsx';
import { Tooltip } from '../Tooltip/Tooltip.tsx';

const renderCheckboxLabel = (
  label: ItemLabelValues,
  tooltip?: string,
  isDimmed?: boolean,
) => {
  const elContent = (
    <div css={styles.selectionListItemLabel}>
      <SelectionListItem content={label} isDimmed={isDimmed} />
    </div>
  );
  return tooltip ? <Tooltip title={tooltip}>{elContent}</Tooltip> : elContent;
};

export type MultiSelectListItem = {
  id: string;
  label: string;
  description?: string;
  checked: boolean;
  isDimmed?: boolean;
  isEnabled?: boolean;
  tooltip?: string;
};

export type ChipFilter = {
  id: string;
  label: string;
  icon: React.ReactElement;
  selected: boolean;
};

export interface IMultiSelectListProps {
  emptyMessage?: string;
  filter?: string;
  chipFilters?: ChipFilter[];
  hasNextPage: boolean | 'LOADING';
  isLoading: boolean;
  items: MultiSelectListItem[];
  onFilterChanged: (to: string) => void;
  onItemSelectionChanged: (id: string, checked: boolean) => void;
  onNextPageRequested?: () => void;
  onChipToggle?: (id: string, to: boolean) => void;
}

export const MultiSelectList: React.FC<IMultiSelectListProps> = ({
  emptyMessage = `There are no results available. Update or remove your filter to get more results.`,
  filter,
  chipFilters,
  hasNextPage,
  isLoading,
  items = [],
  onFilterChanged,
  onItemSelectionChanged,
  onNextPageRequested,
  onChipToggle,
}) => {
  const [statefulFilter, setStatefulFilter] = useEmitDebounce({
    value: filter,
    onDebounce: onFilterChanged,
  });
  const [trackLoadMoreRef, inViewport] = useViewport({ delay: 30 });

  useEffect(() => {
    if (inViewport && hasNextPage === true) onNextPageRequested?.();
  }, [hasNextPage, inViewport, onNextPageRequested]);

  const checkboxOptions = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        label: {
          text: item.label,
          description: item.description,
        },
        isEnabled: item.isEnabled,
        isDimmed: item.isDimmed,
        tooltip: item.tooltip,
      })),
    [items],
  );

  const checkboxSelections = useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        checked: item.checked,
      })),
    [items],
  );

  const handleItemSelectionChecked = useCallback(
    (e: CheckboxGroupEvent) => onItemSelectionChanged(e.id, e.to),
    [onItemSelectionChanged],
  );

  const handleChipToggle = useCallback(
    (chipId: string, to: boolean) => () => onChipToggle?.(chipId, to),
    [onChipToggle],
  );

  const chips = Boolean(chipFilters?.length) && (
    <div css={styles.chipGroup}>
      <Text
        size={13}
        selectable={false}
        uppercase={true}
        weight={700}
        color={color.format(-0.4)}
      >
        {'Filters'}
      </Text>
      <div css={styles.chips}>
        {chipFilters.map((chip) => {
          return (
            <Chip
              key={chip.id}
              label={chip.label}
              labelStyle={styles.chipLabel}
              onClick={handleChipToggle(chip.id, !chip.selected)}
              icon={chip.icon}
              isSelected={chip.selected}
            />
          );
        })}
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {
        <>
          <TextField
            id={'entityFilter'}
            placeholder={'Search'}
            isEnabled={true}
            value={statefulFilter}
            onChange={setStatefulFilter}
            variant={'outlined'}
            InputProps={{
              autoFocus: true,
              startAdornment: (
                <InputAdornment position={'start'}>
                  <Icons.search fill={color.format(-0.18)} />
                </InputAdornment>
              ),
            }}
          />
          {chips}
        </>
      }
      {isLoading && hasNextPage !== 'LOADING' ? (
        <div css={styles.loading}>
          <Spinner />
        </div>
      ) : (
        <div
          key={[
            filter,
            chipFilters
              ?.filter((chipFilter) => chipFilter.selected)
              .map((chipFilter) => chipFilter.id),
          ]
            .filter(Boolean)
            .join('-')}
          css={styles.list}
        >
          {chipFilters?.length > 0 && (
            <Text
              size={13}
              selectable={false}
              uppercase={true}
              weight={700}
              color={color.format(-0.4)}
            >
              {'Results'}
            </Text>
          )}
          {Boolean(items.length) && (
            <CheckboxGroup
              options={checkboxOptions}
              value={checkboxSelections}
              onChange={handleItemSelectionChecked}
              renderLabelOverride={renderCheckboxLabel}
            />
          )}
          {!isLoading && !items.length && (
            <Text
              size={12}
              italic={true}
              color={color.format(-0.5)}
              weight={300}
            >
              {emptyMessage}
            </Text>
          )}
          {hasNextPage === true && (
            <div ref={trackLoadMoreRef} css={styles.loadMore} />
          )}
          {hasNextPage === 'LOADING' && (
            <div css={styles.loading}>
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    width: 400,
    padding: '10px 15px',
    overflow: 'hidden',
  }),
  selectionListItemLabel: css({
    maxWidth: '100%',
  }),
  list: css({
    maxHeight: 525,
    padding: '0px 8px',
    overflowY: 'auto',
  }),
  loading: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    minHeight: 60,
  }),
  loadMore: css({
    height: 1,
  }),
  chipGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '0 8px',
  }),
  chips: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    flexWrap: 'wrap',
  }),
  chipLabel: css({
    fontSize: 14,
  }),
};
