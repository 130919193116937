/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from 'react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { css } from '@emotion/react';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ISheetAuditLogEntry } from '../../../redux/audit/auditSlice.ts';

export interface IEntryAggregateContentProps {
  header: string;
  entries: ISheetAuditLogEntry[];
  actionedByName: string;
}

export const EntryAggregateContent: React.FC<IEntryAggregateContentProps> = ({
  entries,
  actionedByName,
  header,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handlePinClick = useCallback(
    () => setIsExpanded((current) => !current),
    [],
  );

  const summary = useMemo(
    () =>
      `${actionedByName} made ${entries.length} ${str.plural(
        'change',
        entries.length,
      )}.`,
    [actionedByName, entries.length],
  );

  const elInner = useMemo(
    () => isExpanded && <Inner log={entries} />,
    [entries, isExpanded],
  );

  return (
    <div css={styles.base}>
      <div css={styles.header}>
        <Text
          color={color.format(-0.5)}
          weight={FontWeight.black}
          size={18}
          ellipsis={true}
        >
          {header}
        </Text>
      </div>
      <div css={styles.summary}>
        <Text color={color.format(-0.5)} size={14} style={styles.summaryText}>
          {summary}
        </Text>
        <Pin isExpanded={isExpanded} onClick={handlePinClick} />
      </div>
      {elInner}
    </div>
  );
};

/**
 * Pin
 */
interface IPinProps {
  isExpanded?: boolean;
  onClick: () => void;
}

const Pin = ({ isExpanded, onClick }: IPinProps) => {
  const text = isExpanded ? 'Less' : 'More';
  const pin = isExpanded ? Icons.unpinned : Icons.pin;

  return (
    <div css={styles.pin}>
      <TextButton
        size={13}
        onClick={onClick}
        icon={pin}
        iconSize={14}
        iconSide={'RIGHT'}
      >
        {text}
      </TextButton>
    </div>
  );
};

/**
 * Inner
 */
interface IInnerProps {
  log: ISheetAuditLogEntry[];
}

const Inner = ({ log }: IInnerProps) => {
  const toDetailItem = ({ action }: ISheetAuditLogEntry, index: number) => {
    return (
      <li key={index} css={styles.detailListItem}>
        <Text color={color.format(-0.5)} size={14}>
          {action}
        </Text>
      </li>
    );
  };

  return <ul css={styles.detailList}>{log.map(toDetailItem)}</ul>;
};

const styles = {
  base: css({
    boxSizing: 'border-box',
  }),
  header: css({
    padding: '47px 20px 20px 60px',
  }),
  summary: css({
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'stretch',
    padding: '0 20px 10px 60px',
    borderBottom: `1px dashed ${color.format(-0.2)}`,
  }),
  summaryText: css({
    flex: '1 1 auto',
  }),
  pin: css({
    flex: '0 0 auto',
    marginLeft: 30,
  }),
  detailList: css({
    listStyleType: 'none',
    padding: '0 30px 0 60px',
  }),
  detailListItem: css({
    marginTop: '8px 0px',
  }),
};
