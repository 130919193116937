/** @jsxImportSource @emotion/react */
import { viewEntity } from '@seeeverything/ui.dashboards/src/redux/actions.ts';
import {
  useFormsDispatch,
  useFormsSelector,
} from '@seeeverything/ui.forms/src/redux/store.ts';
import {
  DataGrid,
  DataGridClickThroughBehavior,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridClickThroughProps,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridClickThroughBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridClickThroughProps &
    IDataGridProps
>;

export type BulkUploadSuccessInstanceGridProps = {
  hasNextPage?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  loadedPage?: number;
  loadMoreText?: string;
  onColumnSort: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onLoadMore?: () => void;
};

/**
 * Displays successfully created instances from a bulk upload job.
 */
export const BulkUploadSuccessInstanceGrid: React.FC<
  BulkUploadSuccessInstanceGridProps
> = ({
  hasNextPage,
  isLoading,
  isLoadingMore,
  loadedPage,
  loadMoreText,
  onColumnSort,
  onLoadMore,
}) => {
  const dispatch = useFormsDispatch();

  const createdInstances = useFormsSelector(
    (state) => state.formBulkUploadClickThrough.createdInstances,
  );

  const orderBy = useFormsSelector(
    (state) => state.formBulkUploadClickThrough.createdInstances.orderBys,
  );

  const momentTz = useMomentTenantTimezone();

  const data = useMemo(
    () =>
      createdInstances.instances.map((instance) => {
        return {
          id: instance.id,
          data: [
            momentTz(instance.createdAt).format('D MMM YY, h:mm a'),
            instance.templateName,
            instance.assignedToName,
            instance.subjectName,
            momentTz(instance.updatedAt).format('D MMM YY, h:mm a'),
            str.humanize(instance.status, true),
          ],
        };
      }) ?? [],
    [createdInstances, momentTz],
  );

  const filters = useMemo<DataGridFilter[]>(
    () =>
      (orderBy ?? []).map((o) => ({
        columnId: o.fieldName,
        sort: o.direction === 'Ascending' ? 'ASC' : 'DESC',
      })),
    [orderBy],
  );

  const handleInstanceClick = useCallback(
    (instanceId: string) => {
      const instanceName = createdInstances.instances.find(
        (instance) => instanceId === instance.id,
      )?.templateName;

      dispatch(viewEntity('', instanceId, 'Form', instanceName));
    },
    [createdInstances, dispatch],
  );

  return (
    <Grid
      id={'BulkUploadSucceededGrid'}
      clickThroughColumnIds={['templateName']}
      columns={COLUMNS}
      data={data}
      filters={filters}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      isLoadingPage={isLoadingMore}
      isScrollable={true}
      isVirtualized={true}
      loadedPage={loadedPage}
      loadMoreText={loadMoreText}
      onClickThroughColumnClick={handleInstanceClick}
      onColumnSort={onColumnSort}
      onLoadPage={onLoadMore}
    />
  );
};

const COLUMNS: IDataGridColumn[] = [
  {
    id: 'createdAt',
    label: 'Created',
    width: '*',
    borderRight: true,
  },
  {
    id: 'templateName',
    label: 'Name',
    width: '2*',
    isSortable: false,
    borderRight: true,
  },
  {
    id: 'assignedToName',
    label: 'Assigned To',
    isSortable: false,
    width: '2*',
  },
  {
    id: 'subjectName',
    label: 'Subject',
    width: '2*',
    isSortable: false,
    borderRight: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    width: '*',
    borderRight: true,
  },
  {
    id: 'status',
    label: 'Status',
    width: '*',
    borderRight: true,
  },
];
