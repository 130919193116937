/** @jsxImportSource @emotion/react */
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { SheetAuditContainer } from '@seeeverything/ui.shell/src/components/SheetAudit/SheetAuditContainer.tsx';
import { SheetAutomatedActions } from '@seeeverything/ui.shell/src/components/SheetAutomatedActions/SheetAutomatedActions.tsx';
import { SheetBulkUploadClickThroughGridContainer } from '@seeeverything/ui.shell/src/components/SheetBulkUploadClickThroughGrid/SheetBulkUploadClickThroughGridContainer.tsx';
import { SheetCommentsListContainer } from '@seeeverything/ui.shell/src/components/SheetCommentsList/SheetCommentsListContainer.tsx';
import { SheetDashboardV2Container } from '@seeeverything/ui.shell/src/components/SheetDashboardV2/SheetDashboardV2Container.tsx';
import { SheetFormContainer } from '@seeeverything/ui.shell/src/components/SheetForm/SheetFormContainer.tsx';
import { SheetFormDesignerContainer } from '@seeeverything/ui.shell/src/components/SheetFormDesigner/SheetFormDesignerContainer.tsx';
import { SheetFormTemplatesContainer } from '@seeeverything/ui.shell/src/components/SheetFormTemplates/SheetFormTemplatesContainer.tsx';
import { SheetFormsBulkUploadHistoryContainer } from '@seeeverything/ui.shell/src/components/SheetFormsBulkUploadHistory/SheetFormsBulkUploadHistoryContainer.tsx';
import { SheetGoalsActionsGridContainer } from '@seeeverything/ui.shell/src/components/SheetGoalsActionsGrid/SheetGoalsActionsGridContainer.tsx';
import { SheetGridV2Container } from '@seeeverything/ui.shell/src/components/SheetGridV2/SheetGridV2Container.tsx';
import { SheetSchedulesContainer } from '@seeeverything/ui.shell/src/components/SheetSchedules/SheetSchedulesContainer.tsx';
import { DashboardV2TemplatesByModule } from '@seeeverything/ui.shell/src/components/types.ts';
import { SheetType } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { TenantFeatures } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { AuthenticatedUser } from '@seeeverything/ui.util/src/types.ts';
import { BrandingPanel } from '../components/BrandingPanel/BrandingPanel.tsx';
import { FeedbackPanel } from '../components/FeedbackPanel/FeedbackPanel.tsx';

export interface SheetContentFactoryProps {
  type: SheetType;
  tenantFeatures: TenantFeatures;
  authenticatedUser: AuthenticatedUser;
  dashboardV2Templates: DashboardV2TemplatesByModule;
  isLoading?: boolean;
}

export type SheetContentFactoryType = () => (
  props: SheetContentFactoryProps & any,
) => JSX.Element;

export const sheetContentFactory: SheetContentFactoryType = () =>
  function SheetContent(props) {
    const { tenantFeatures, isLoading, type } = props;

    if (isLoading) {
      return <Spinner center={true} />;
    }

    switch (type) {
      case 'COACHING_DASHBOARD_PERSON':
      case 'COACHING_DASHBOARD_TEAM':
        return (
          <SheetDashboardV2Container
            id={props.id}
            dashboardType={props.dashboardType}
            leftPanelBottomContent={
              tenantFeatures?.DASHBOARDS.FEEDBACK_PANEL ? (
                <FeedbackPanel />
              ) : (
                <BrandingPanel />
              )
            }
          />
        );

      case 'DASHBOARD_CLICK_THROUGH_GRID':
      case 'V2_GRID':
        return (
          <SheetGridV2Container
            dashboardKey={props.dashboardKey}
            dashboardType={props.dashboardType}
          />
        );

      case 'GOALS_ACTIONS_GRID': {
        return (
          <SheetGoalsActionsGridContainer
            entityId={props.entityId}
            entityType={props.entityType}
            startDate={props.startDate}
            endDate={props.endDate}
            dateRangeLabel={props.dateRangeLabel}
          />
        );
      }

      case 'BULK_UPLOAD_CLICK_THROUGH_GRID':
        return <SheetBulkUploadClickThroughGridContainer />;

      case 'COMMENTS_LIST':
        return (
          <SheetCommentsListContainer
            dashboardComponentId={props.dashboardComponentId}
          />
        );

      case 'FORM':
        return (
          <SheetFormContainer
            instanceId={props.instanceId}
            header={props.header}
          />
        );

      case 'FORM_AUDIT_LOG':
        return <SheetAuditContainer />;

      case 'FORMS_BULK_UPLOAD_HISTORY':
        return <SheetFormsBulkUploadHistoryContainer />;

      case 'FORMS_DESIGNER':
        return <SheetFormDesignerContainer />;

      case 'FORMS_DESIGNER_LIST':
        return <SheetFormTemplatesContainer />;

      case 'SETTINGS_SCHEDULES':
        return <SheetSchedulesContainer />;

      case 'SETTINGS_AUTOMATED_ACTIONS':
        return <SheetAutomatedActions />;

      default:
        throw new Error(`Factory: Sheets type '${type}' not supported.`);
    }
  };
