/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/Image.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { SEEEVERYTHING_CDN } from '@seeeverything/ui.util/src/constants/cdn.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';

export const FeedbackPanel: React.FC = () => {
  return (
    <div css={styles.base}>
      <div>
        <a
          href={'https://seeeverything.com/'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          <Image
            path={`${SEEEVERYTHING_CDN}/branding/logo-square-100.png`}
            width={50}
            height={50}
          />
        </a>
      </div>
      <div>
        <div css={styles.textPadding}>
          <Text size={13} color={'rgba(0, 0, 0, 0.4)'}>
            {TEXT_TOP}
          </Text>
        </div>
        <div css={styles.textPadding}>
          <Text size={13} color={'rgba(0, 0, 0, 0.4)'}>
            {TEXT_MID}
          </Text>
        </div>
        <div>
          <a
            href={
              'mailto:productfeedback@seeeverything.com?Subject=Give%20feedback%20on%20the%20product'
            }
          >
            <div css={styles.hyperlink}>{TEXT_BOTTOM}</div>
          </a>
        </div>
      </div>
    </div>
  );
};

const TEXT_TOP = `Want to give us feedback?`;
const TEXT_MID = `Tell us how we can improve.`;
const TEXT_BOTTOM = `Send us an email.`;

const styles = {
  base: css({
    minHeight: 100,
    maxHeight: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderTop: 'solid 1px #EDEDED',
    borderRadius: '4px 4px 0px 0px',
  }),
  textPadding: css({
    paddingBottom: 2,
  }),
  hyperlink: css({
    paddingTop: 2,
    fontSize: 13,
    color: COLORS.BLUE,
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline',
      textDecorationColor: COLORS.BLUE,
    },
  }),
};
