/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { AuditLogEntry } from '../../../redux/audit/auditSlice.ts';
import { EntryAggregateContent } from './EntryAggregateContent.tsx';
import { EntryIndex } from './EntryIndex.tsx';
import { EntryMarkdownContent } from './EntryMarkdownContent.tsx';
import { EntryTextContent } from './EntryTextContent.tsx';

export interface IAuditLogProps {
  logEntries: AuditLogEntry[];
}

export const AuditLog: React.FC<IAuditLogProps> = ({ logEntries }) => {
  return (
    <div css={styles.base}>
      {logEntries?.map((auditEntry, index) => {
        const actionedAt =
          auditEntry.kind === 'aggregate'
            ? auditEntry.actionedAt.start
            : auditEntry.actionedAt;

        return (
          <div key={index} css={styles.logEntry}>
            <div css={styles.leftIndex}>
              <EntryIndex
                actionedAt={actionedAt}
                actionedByName={auditEntry.actionedBy.name}
              />
            </div>
            <div css={styles.icon}>
              <Icons.chatBubble fill={'#CFCFCF'} size={36} />
            </div>
            <div css={styles.rightDetail}>
              {auditEntry.kind === 'aggregate' && (
                <EntryAggregateContent
                  header={auditEntry.action}
                  entries={auditEntry.entries}
                  actionedByName={auditEntry.actionedBy.name}
                />
              )}
              {auditEntry.kind === 'entry' && (
                <EntryTextContent header={auditEntry.action} />
              )}
              {auditEntry.kind === 'markdown' && (
                <EntryMarkdownContent markdown={auditEntry.action} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  base: css({
    overflowY: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  logEntry: css({
    display: 'flex',
    ':last-child': {
      flex: 1,
    },
  }),
  leftIndex: css({
    flex: '0.3 0 0',
    backgroundColor: color.format(-0.02),
    borderRight: `solid 1px ${color.format(-0.2)}`,
    paddingBottom: 10,
  }),
  rightDetail: css({
    flex: '1 0 0',
  }),
  icon: css({
    position: 'relative',
    top: 42,
    left: -18,
    width: 0,
  }),
};
