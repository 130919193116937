/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/TextLink.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { FormPerson, FormTeam } from '../../redux/form-instance/types.ts';
import { ButtonRevert } from './components/ButtonRevert.tsx';

const RevertMessage: React.FC = () => (
  <Text color={BAR_THEME.messageColor}>
    {'This has been signed off. Re-open to make changes.'}
  </Text>
);

interface IAwaitingReviewMessageProps {
  formLabel: string;
  onAwaitingReviewClick?: () => void;
}

//Using \u00A0 as a non-breaking space to preserve the sentence appearance.
const AwaitingReviewMessage = ({
  formLabel,
  onAwaitingReviewClick,
}: IAwaitingReviewMessageProps) => {
  const responseMessage = 'your response.';
  const responseLink = onAwaitingReviewClick ? (
    <TextLink onClick={onAwaitingReviewClick}>{responseMessage}</TextLink>
  ) : (
    <Text color={BAR_THEME.messageColor}>{responseMessage}</Text>
  );
  return (
    <>
      <Text
        color={BAR_THEME.messageColor}
      >{`This ${formLabel} requires\u00A0`}</Text>
      {responseLink}
    </>
  );
};

interface IAwaitingReviewSubjectMessageProps {
  formLabel: string;
  subject?: FormPerson | FormTeam;
  onAwaitingReviewClick?: () => void;
}

//Using \u00A0 as a non-breaking space to preserve the sentence appearance.
const AwaitingReviewSubjectMessage = ({
  formLabel,
  subject,
  onAwaitingReviewClick,
}: IAwaitingReviewSubjectMessageProps) => {
  const subjectName = subject?.name ?? 'subject';
  const responseMessage = `response from ${subjectName}.`;
  const responseLink = onAwaitingReviewClick ? (
    <TextLink onClick={onAwaitingReviewClick}>{responseMessage}</TextLink>
  ) : (
    <Text color={BAR_THEME.messageColor}>{responseMessage}</Text>
  );
  return (
    <>
      <Text
        color={BAR_THEME.messageColor}
      >{`This ${formLabel} requires a\u00A0`}</Text>
      {responseLink}
    </>
  );
};

interface IAwaitingReviewReviewerMessageProps {
  formLabel: string;
  reviewer?: FormPerson;
  onAwaitingReviewClick?: () => void;
}

//Using \u00A0 as a non-breaking space to preserve the sentence appearance.
const AwaitingReviewReviewerMessage = ({
  formLabel,
  reviewer,
  onAwaitingReviewClick,
}: IAwaitingReviewReviewerMessageProps) => {
  const reviewerName = reviewer?.name ?? 'reviewer';
  const responseMessage = `sign-off from ${reviewerName}.`;
  const responseLink = onAwaitingReviewClick ? (
    <TextLink onClick={onAwaitingReviewClick}>{responseMessage}</TextLink>
  ) : (
    <Text color={BAR_THEME.messageColor}>{responseMessage}</Text>
  );
  return (
    <>
      <Text
        color={BAR_THEME.messageColor}
      >{`This ${formLabel} requires\u00A0`}</Text>
      {responseLink}
    </>
  );
};

const ValidationMessage = () => {
  return (
    <Text
      color={BAR_THEME.messageColor}
    >{`You've missed something, please check for sections highlighted in red and try again.`}</Text>
  );
};

const GeneralErrorMessage = () => {
  return (
    <Text color={BAR_THEME.messageColor}>
      {
        'There was a problem saving your change. Please contact your support team.'
      }
    </Text>
  );
};

interface IUnderAppealedMessageProps {
  formLabel: string;
}

const UnderAppealedMessage = ({ formLabel }: IUnderAppealedMessageProps) => {
  return (
    <Text color={BAR_THEME.messageColor}>
      {`This ${formLabel} is currently under appeal.`}
    </Text>
  );
};

interface IUnderAppealedReopenMessageProps {
  formLabel: string;
}

const UnderAppealedReopenMessage = ({
  formLabel,
}: IUnderAppealedReopenMessageProps) => {
  return (
    <Text color={BAR_THEME.messageColor}>
      {`This ${formLabel} has been appealed. To process the appeal, start by reverting reviewer sign-off.`}
    </Text>
  );
};

const SIGNED_OFF_BAR_COLOR = color.create(COLORS.BLUE).alpha(0.1).css();

const BAR_THEME = {
  FORM_SIGNED_OFF: {
    bg: SIGNED_OFF_BAR_COLOR,
    border: SIGNED_OFF_BAR_COLOR,
    iconColor: '#37BA54',
    icon: 'verifiedUser',
    message: RevertMessage,
  },
  AWAITING_REVIEW: {
    bg: 'rgba(249, 195, 102, 0.3)',
    border: 'rgba(249, 195, 102, 0.1)',
    iconColor: color.format(-0.7),
    icon: 'update',
    message: AwaitingReviewMessage,
  },
  AWAITING_REVIEW_SUBJECT: {
    bg: 'rgba(249, 195, 102, 0.3)',
    border: 'rgba(249, 195, 102, 0.1)',
    iconColor: color.format(-0.7),
    icon: 'update',
    message: AwaitingReviewSubjectMessage,
  },
  AWAITING_REVIEW_REVIEWER: {
    bg: 'rgba(249, 195, 102, 0.3)',
    border: 'rgba(249, 195, 102, 0.1)',
    iconColor: color.format(-0.7),
    icon: 'update',
    message: AwaitingReviewReviewerMessage,
  },
  VALIDATION_ERROR: {
    bg: 'rgba(255, 0, 0, 0.1)',
    border: 'rgba(255, 0, 0, 0.05)',
    iconColor: 'red',
    icon: 'errorOutline',
    message: ValidationMessage,
  },
  GENERAL_ERROR: {
    bg: 'rgba(255, 0, 0, 0.1)',
    border: 'rgba(255, 0, 0, 0.05)',
    iconColor: 'red',
    icon: 'errorOutline',
    message: GeneralErrorMessage,
  },
  UNDER_APPEAL: {
    bg: 'rgba(249, 195, 102, 0.3)',
    border: 'rgba(249, 195, 102, 0.1)',
    iconColor: color.format(-0.7),
    icon: 'update',
    message: UnderAppealedMessage,
  },
  UNDER_APPEAL_REOPEN: {
    bg: 'rgba(249, 195, 102, 0.3)',
    border: 'rgba(249, 195, 102, 0.1)',
    iconColor: color.format(-0.7),
    icon: 'update',
    message: UnderAppealedReopenMessage,
  },
  messageColor: color.format(-0.7),
};

export type FormStatusBarType =
  | 'VALIDATION_ERROR'
  | 'GENERAL_ERROR'
  | 'FORM_SIGNED_OFF'
  | 'AWAITING_REVIEW'
  | 'AWAITING_REVIEW_SUBJECT'
  | 'AWAITING_REVIEW_REVIEWER'
  | 'UNDER_APPEAL'
  | 'UNDER_APPEAL_REOPEN';

export interface IFormStatusBarProps {
  type: FormStatusBarType;
  formLabel?: string;
  subject?: FormPerson | FormTeam;
  reviewer?: FormPerson;
  onAwaitingReviewClick?: () => void;
  onRevert: () => void;
}

/**
 * Message bar that informs the user the current status of a form and offers appropriate interactions where necessary.
 */
export const FormStatusBar: React.FC<IFormStatusBarProps> = ({
  onRevert,
  formLabel = 'Session',
  subject,
  reviewer,
  onAwaitingReviewClick,
  type,
}) => {
  const theme = BAR_THEME[type];
  const computedStyles = {
    base: css({
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: '1 1 auto',
      border: 'solid 1px',
      padding: '0 8px',
      boxSizing: 'border-box',
      backgroundColor: theme.bg,
      borderColor: theme.border,
      maxHeight: 50,
    }),
  };

  const Icon = Icons[theme.icon];
  const elIcon = <Icon size={20} fill={theme.iconColor} />;

  const isRevertEnabled =
    onRevert && (type === 'FORM_SIGNED_OFF' || type === 'UNDER_APPEAL_REOPEN');
  const elButtonRevert = isRevertEnabled && (
    <div css={styles.buttonContainer}>
      <ButtonRevert onRevert={onRevert} />
    </div>
  );

  return (
    <div css={computedStyles.base}>
      <div css={styles.iconContainer}>{elIcon}</div>
      <div data-test={'forms-formStatusBar'} css={styles.textContainer}>
        {theme.message({
          formLabel: formLabel?.toLowerCase(),
          subject,
          reviewer,
          onAwaitingReviewClick: onAwaitingReviewClick,
        })}
      </div>
      {elButtonRevert}
    </div>
  );
};

const styles = {
  iconContainer: css({
    display: 'flex',
    marginLeft: 10,
    marginRight: 10,
  }),
  textContainer: css({
    flex: '1 1 auto',
  }),
  buttonContainer: css({
    flex: '180px 0 0',
  }),
};
