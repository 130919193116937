/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Image } from '@seeeverything/ui.primitives/src/components/Image/Image.tsx';
import { SEEEVERYTHING_CDN } from '@seeeverything/ui.util/src/constants/cdn.ts';

export const BrandingPanel: React.FC = () => {
  return (
    <div css={styles.base}>
      <div>
        <a
          href={'https://seeeverything.com/'}
          target={'_blank'}
          rel={'noreferrer'}
        >
          <Image
            path={`${SEEEVERYTHING_CDN}/branding/logo-small.png`}
            width={133}
            height={50}
          />
        </a>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    padding: '0 0 15px 15px',
  }),
};
