/** @jsxImportSource @emotion/react */
import {
  ISelectionListColumn,
  ISelectionListItem,
  SelectionListChangedEvent,
  SelectionListClickEvent,
  SelectionListEdgeEvent,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { events } from '@seeeverything/ui.util/src/events.rx/index.ts';
import { delay } from '@seeeverything/ui.util/src/timer/timer.ts';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { distinctUntilKeyChanged, filter, map, Subject, takeUntil } from 'rxjs';
import { IQueryDropdownProps } from '../../api/api.queryBuilder/types.ts';
import { inlineFilterClicked } from '../../redux/query/actions.dropdown.ts';
import { ShellAction, ShellState } from '../../redux/types.ts';
import { DropdownList, DropdownListFilterType } from './DropdownList.tsx';
import { DropdownListInlineFilterableBehavior } from './DropdownListInlineFilterableBehavior.tsx';
import { findInColumn, firstFuzzyMatch, fuzzyMatches } from './util.ts';

const InlineFilterableDropdownList =
  DropdownListInlineFilterableBehavior(DropdownList);

export interface IDropdownListContainerProps extends IQueryDropdownProps {
  column?: ISelectionListColumn;
  filter?: string;
  filterType?: DropdownListFilterType;
}
interface IViewProps extends IDropdownListContainerProps {
  url: string;
  dispatch: (action: ShellAction) => void;
}
export interface IViewState {
  selectedId?: string | number;
}

class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {};
  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IViewProps>();
  private state$ = new Subject<IViewState>();

  private get columns() {
    // NB:  Array is provided here as legacy before
    //      <SelectionListHierarchyViewport> was used.
    return this.props.column ? [this.props.column] : [];
  }

  public shouldComponentUpdate(nextProps: IViewProps, nextState: IViewState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  private get selectedItem() {
    const { selectedId } = this.state;
    return findInColumn(
      (item) => item.id === selectedId && item,
      this.props.column,
    )?.item;
  }

  private get autocompleteItem() {
    // If there is a selected item, return it.
    const item = this.selectedItem;
    if (item) {
      return item;
    }

    // Look for the first filtered item.
    const searchFilter = this.props.filter;
    const match = firstFuzzyMatch(searchFilter, this.columns);
    if (match) {
      return match.item;
    }

    // Nothing automatically selectable.
    return;
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }
  public UNSAFE_componentWillMount() {
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    const state$ = this.state$.pipe(takeUntil(this.unmounted$));
    const keyup$ = events.keyup$.pipe(takeUntil(this.unmounted$));
    const autocomplete$ = this.props.autocomplete$.pipe(
      takeUntil(this.unmounted$),
    );

    state$.subscribe((state) => this.setState(state));

    props$
      // Reset component state when URL changed.
      .pipe(map((props) => props.url))
      .subscribe(() => this.setSelectedId(undefined));

    keyup$
      // Ensure the first item is selected when the
      // user [DownArrow]'s into the dropdown.
      .pipe(
        filter(
          (e) =>
            e.code === 'ArrowDown' &&
            this.props.isActive &&
            !this.state.selectedId,
        ),
      )
      .subscribe(() => {
        const searchFilter = this.props.filter;
        if (searchFilter) {
          // Select the first item within the filter.
          const filteredItems = fuzzyMatches(searchFilter, this.columns).filter(
            (column) => itemTypeFilter(column.item),
          );
          if (filteredItems.length > 0) {
            this.setSelectedId(filteredItems[0].item.id);
          }
        } else {
          // Select the first item.
          this.selectFirstItem();
        }
      });

    autocomplete$
      // Attempt to auto-complete when requested.
      .subscribe(() => {
        this.autocomplete(this.autocompleteItem);
      });

    // As the filter changes, if the selected item becomes dim move
    // selection to the first newly filtered item.
    props$
      .pipe(
        distinctUntilKeyChanged('filter'),
        filter((e) => Boolean(e.filter && e.filter.trim())),
      )
      .subscribe((e) => {
        const selectedId = this.state.selectedId;
        const columns = e.column ? [e.column] : [];
        const filteredItems = fuzzyMatches(e.filter, columns).filter((column) =>
          itemTypeFilter(column.item),
        );
        const isWithinFilter =
          selectedId &&
          filteredItems
            .map((m) => m.item)
            .some((item) => item.id === selectedId);
        if (selectedId && !isWithinFilter && filteredItems.length > 0) {
          this.setSelectedId(filteredItems[0].item.id);
        }
      });

    // Send initial props to the Observable.
    this.props$.next(this.props);
  }
  public UNSAFE_componentWillReceiveProps(nextProps: IViewProps) {
    this.props$.next(nextProps);
  }

  public render() {
    const { column } = this.props;
    const { inlineFilters, activeInlineFilters, ...rest } = this.props;

    const inlineFiltersWithClickHandlersAndSelectionState = inlineFilters?.map(
      (inlineFilter) => ({
        ...inlineFilter,
        onClick: this.handleInlineFilterClick,
        isSelected: activeInlineFilters?.includes(inlineFilter.id),
      }),
    );

    return (
      <InlineFilterableDropdownList
        {...rest}
        column={column}
        selectedId={this.state.selectedId}
        onSelectionChanged={this.handleItemSelectionChanged}
        onEdgeSelected={this.handleEdgeSelected}
        onClick={this.handleListItemClick}
        inlineFilters={inlineFiltersWithClickHandlersAndSelectionState}
      />
    );
  }

  private handleInlineFilterClick = (id: string) => {
    const { dispatch, data, query, selection } = this.props;
    dispatch(inlineFilterClicked(id, data, query, selection));
  };

  private selectFirstItem = () => {
    const item = getFirstItem(this.columns);
    this.setSelectedId(item && item.id);
  };

  private handleItemSelectionChanged = (e: SelectionListChangedEvent) => {
    const selectedId = e.to.item && e.to.item.id;
    this.setSelectedId(selectedId);
  };

  private setSelectedId(selectedId?: number | string) {
    this.state$.next({ selectedId });
  }

  private handleListItemClick = (e: SelectionListClickEvent) => {
    this.autocomplete(e.item);
  };

  private autocomplete = (item?: ISelectionListItem) => {
    const items = fuzzyMatches(this.props.filter, this.columns).filter(
      (column) => itemTypeFilter(column.item),
    );
    const context = item
      ? { id: item.id, label: item.content, value: item.value, items }
      : { items };
    this.props.actions.autocomplete(context);
  };

  private handleEdgeSelected = (e: SelectionListEdgeEvent) => {
    const { actions } = this.props;
    if (e.edge === 'TOP') {
      actions.releaseFocus();
      delay(0, () => {
        // NB:  Delay to ensure other actions in the firing sequence
        //      don't cause the first item to become reselected.
        this.setSelectedId(undefined);
      });
    }
  };
}

const itemTypeFilter = (item: ISelectionListItem) => {
  return item.type !== 'SECTION' && item.type !== 'DIVIDER';
};

const getFirstItem = (columns?: ISelectionListColumn[]) =>
  columns &&
  columns[0] &&
  columns[0].items.find((item) => itemTypeFilter(item));

const mapStateToProps = (state: ShellState) => ({
  url: state.query.url,
});

const mapDispatchToProps = (dispatch: (action: ShellAction) => void) => ({
  dispatch,
});

/**
 * The Redux container for a DropdownList.
 */
export const DropdownListContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
