/** @jsxImportSource @emotion/react */
import { isAnyOf } from '@reduxjs/toolkit';
import { formBulkUploadClickThroughSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadClickThrough/index.ts';
import { formBulkUploadDownloadFailedRowsSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadDownloadFailedRows/index.ts';
import { formBulkUploadDownloadTemplateSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadDownloadTemplate/index.ts';
import { formBulkUploadImportFileSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadImportFile/index.ts';
import { formBulkUploadJobHistorySlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadJobHistory/index.ts';
import { BulkUploadDuplicateRowWarningDialogContainer } from '@seeeverything/ui.shell/src/components/BulkUploadDuplicateRowWarningDialog/BulkUploadDuplicateRowWarningDialogContainer.tsx';
import { BulkUploadFileImportDialogContainer } from '@seeeverything/ui.shell/src/components/BulkUploadFileImportDialog/BulkUploadFileImportDialogContainer.tsx';
import { DownloadFormTemplateDialogContainer } from '@seeeverything/ui.shell/src/components/DownloadFormTemplateDialog/index.ts';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetToolbarClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import momentTz from 'moment';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { concatAll, filter, from, map, Observable } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import { addChipAction } from '../../config.sheets/index.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  bulkUploadDownloadFailuresForJobEpic,
  bulkUploadDownloadFormTemplateEpic,
  bulkUploadExportFormTemplateEpic,
  bulkUploadShowDuplicateRowDialogEpic,
  bulkUploadShowImportFileEpic,
  bulkUploadCancelDuplicateRowImportEpic,
  closeDialogOnBulkUploadImportStartedEpic,
  downloadTemplateCancelledEpic,
  refreshJobHistoryEpic,
  loadClickThroughForJobEpic,
  showStatusOnFormBulkFailuresDownloadFailedEpic,
  showStatusOnFormBulkValidationFailedEpic,
  showStatusOnFormBulkFailuresDownloadRequests,
  showStatusBarOnBulkUploadImportEpic,
  showStatusBarOnJobHistoryLoadErrorEpic,
);

function showStatusOnFormBulkFailuresDownloadRequests(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadDownloadFailedRowsSlice.requestDownloadLink.match),
    map(() =>
      showStatusBar(
        'INFO',
        `A .CSV file containing the failures is being generated for you. Once ready, it will automatically be downloaded by your browser.`,
        5000,
      ),
    ),
  );
}

function showStatusOnFormBulkFailuresDownloadFailedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadDownloadFailedRowsSlice.downloadFailed.match),
    map(() =>
      showStatusBar(
        'ERROR',
        'Something went wrong when trying to download the failures for the job. Please try again.',
        5000,
      ),
    ),
  );
}

function showStatusOnFormBulkValidationFailedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.fileValidatedError.match),
    map(() =>
      showStatusBar(
        'ERROR',
        'Something went wrong when trying to validate the file. Please try again.',
        5000,
      ),
    ),
  );
}

/**
 * Epic to show status bar when bulk uploader imports files.
 */
function showStatusBarOnBulkUploadImportEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.importFile.match),
    map(() => {
      const locale = state$.value.tenantState.tenant?.locale;
      const forms = str.plural(locale.label.form).toLowerCase();

      return showStatusBar(
        'INFO',
        `Upload will start now. Refresh to see latest status. Note this may take several minutes before all the created ${forms} are visible in dashboards.`,
      );
    }),
  );
}

function closeDialogOnBulkUploadImportStartedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.importFileStarted.match),
    map(() => {
      return from([
        hideModalDialog(),
        formBulkUploadJobHistorySlice.loadJobs({ loadNextPage: false }),
      ]);
    }),
    concatAll(),
  );
}

function showStatusBarOnJobHistoryLoadErrorEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadJobHistorySlice.loadJobsFailed.match),
    map(() =>
      showStatusBar('ERROR', 'An error occurred loading job histories.'),
    ),
  );
}

function loadClickThroughForJobEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        formBulkUploadClickThroughSlice.loadFailedClickThrough.match,
        formBulkUploadClickThroughSlice.loadSucceededClickThrough.match,
      ),
    ),
    map((action) => {
      const locale = state$.value.tenantState.tenant.locale;
      const timezone = state$.value.tenantState.tenant.configuration.timezone;
      const jobs = state$.value.formBulkUploadJobHistory.jobs;
      const job = jobs.find(({ id }) => id === action.payload.jobId);

      const isSucceededClickThrough =
        formBulkUploadClickThroughSlice.loadSucceededClickThrough.match(action);

      const jobDate = momentTz(job.uploadedAt)
        .tz(timezone)
        .format('D MMM YY, h:mm a');
      const chipLabel = `${jobDate} - ${
        isSucceededClickThrough ? 'Created' : 'Failed'
      } ${str.plural(locale.label.form)}`;

      return addChipAction({
        type: isSucceededClickThrough
          ? 'bulkUploadSucceeded'
          : 'bulkUploadFailed',
        label: chipLabel,
        id: action.payload.jobId,
      });
    }),
  );
}

function bulkUploadDownloadFailuresForJobEpic(
  action$: Observable<SheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter(
      (action) => action.payload.toolId === 'DOWNLOAD_BULK_UPLOAD_JOB_FAILURES',
    ),
    map(() => {
      const jobId = state$.value.formBulkUploadClickThrough?.jobId;
      return formBulkUploadDownloadFailedRowsSlice.requestDownloadLink({
        jobId,
      });
    }),
  );
}

function bulkUploadDownloadFormTemplateEpic(
  action$: Observable<SheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'DOWNLOAD_FORM_TEMPLATE'),
    map(() =>
      from([
        showModalDialog({
          content: <DownloadFormTemplateDialogContainer />,
          width: 830,
        }),
        formBulkUploadDownloadTemplateSlice.clearTemplate(),
      ]),
    ),
    concatAll(),
  );
}

function refreshJobHistoryEpic(action$: Observable<SheetToolbarClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'IMPORT_BULK_UPLOAD_REFRESH'),
    map(() => formBulkUploadJobHistorySlice.loadJobs({ loadNextPage: false })),
  );
}

function downloadTemplateCancelledEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.cancelExport.match),
    map(hideModalDialog),
  );
}

function bulkUploadExportFormTemplateEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formBulkUploadDownloadTemplateSlice.exportTemplate.match),
    map((action) => {
      return from([
        hideModalDialog(),
        showStatusBar(
          'INFO',
          `The template file for ${action.payload.selectedTemplate.name} will be automatically downloaded by your browser.`,
        ),
      ]);
    }),
    concatAll(),
  );
}

/**
 * Epic to show bulk upload duplicate row warning dialog.
 */
function bulkUploadShowDuplicateRowDialogEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.showDuplicateRowImportDialog.match),
    map(() =>
      showModalDialog({
        content: <BulkUploadDuplicateRowWarningDialogContainer />,
        width: 300,
      }),
    ),
  );
}

/**
 * Epic to show bulk upload import file dialog.
 */
function bulkUploadShowImportFileEpic(
  action$: Observable<SheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'IMPORT_BULK_UPLOAD_FILE'),
    map(() =>
      from([
        showModalDialog({
          content: <BulkUploadFileImportDialogContainer />,
          width: 1000,
        }),
        formBulkUploadImportFileSlice.clear(),
      ]),
    ),
    concatAll(),
  );
}

/**
 * Epic to show bulk upload import file dialog when user
 * cancelled import from duplicate row warning dialog.
 */
function bulkUploadCancelDuplicateRowImportEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formBulkUploadImportFileSlice.cancelDuplicateRowImport.match),
    map(() =>
      from([
        showModalDialog({
          content: <BulkUploadFileImportDialogContainer />,
          width: 1000,
        }),
      ]),
    ),
    concatAll(),
  );
}
