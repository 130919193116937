/** @jsxImportSource @emotion/react */
import { DistributionListGridContainer } from '@seeeverything/ui.forms/src/components/DistributionListGrid/DistributionListGridContainer.tsx';
import { GoalScheduleGridContainer } from '@seeeverything/ui.forms/src/components/GoalScheduleGrid/GoalScheduleGridContainer.tsx';
import { InstanceScheduleGridContainer } from '@seeeverything/ui.forms/src/components/InstanceScheduleGrid/InstanceScheduleGridContainer.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useMemo } from 'react';
import { useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/index.ts';

/**
 * A SheetLayout that displays an editable Form Schedule.
 */
export const SheetSchedulesContainer: React.FC = () => {
  const selectedScheduleType = useShellSelector(
    (state) => state.sheetSchedules.selectedScheduleType,
  );
  const title = useShellSelector(
    (state) => state.tenantState.tenant.locale.label.schedules,
  );

  const tenantFeatures = useShellSelector(
    (state) => state.tenantState.tenant.features,
  );
  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const rightTools = useMemo(() => {
    const canScheduleForms = tenantFeatures.QUERY.SETTINGS_FORM_SCHEDULES;
    const canScheduleGoals = tenantFeatures.QUERY.SETTINGS_GOAL_SCHEDULES;

    return [
      canScheduleForms && {
        id: 'INSTANCE_TIME_SCHEDULES',
        icon: Icons.list,
        label: str.plural(locale.label.form),
      },
      canScheduleForms && {
        id: 'DISTRIBUTION_LIST',
        icon: Icons.list,
        label: 'Lists',
      },
      canScheduleGoals && {
        id: 'GOAL_SCHEDULES',
        icon: Icons.list,
        label: 'Goals',
      },
    ].filter(Boolean);
  }, [tenantFeatures, locale]);

  return (
    <SheetLayout
      selectedToolbarToolIds={[selectedScheduleType]}
      overrideNoScrollPanel={true}
      title={title}
      leftTools={[
        {
          id: 'CREATE_SCHEDULE',
          icon: Icons.addBox,
          label:
            selectedScheduleType === 'DISTRIBUTION_LIST'
              ? 'Add a Distribution List'
              : 'Add a Schedule',
        },
      ]}
      rightTools={rightTools}
    >
      {selectedScheduleType === 'GOAL_SCHEDULES' && (
        <GoalScheduleGridContainer />
      )}
      {selectedScheduleType === 'INSTANCE_TIME_SCHEDULES' && (
        <InstanceScheduleGridContainer />
      )}
      {selectedScheduleType === 'DISTRIBUTION_LIST' && (
        <DistributionListGridContainer />
      )}
    </SheetLayout>
  );
};
