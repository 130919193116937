/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

export interface IEntryIndexProps {
  actionedAt: string;
  actionedByName: string;
}

export const EntryIndex: React.FC<IEntryIndexProps> = ({
  actionedAt,
  actionedByName,
}) => {
  return (
    <div css={styles.base}>
      <Text
        color={color.format(-0.5)}
        block={true}
        align={'right'}
        size={14}
        weight={FontWeight.black}
      >
        {moment(actionedAt).format('D MMM YY, h:mm a')}
      </Text>
      <Text
        color={color.format(-0.5)}
        block={true}
        align={'right'}
        size={14}
        marginTop={4}
      >
        {actionedByName}
      </Text>
    </div>
  );
};

const styles = {
  base: css({
    boxSizing: 'border-box',
    minWidth: 250,
    padding: '40px 60px 40px 20px',
  }),
};
