/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormActionStatus,
  FormActionStatusWithOverdue,
  FormActionVerificationStatus,
  FormInstanceIssue,
} from '@se/data/forms/types.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import {
  todayInTenantTz,
  useDateContext,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo } from 'react';
import { PersonSelection } from '../../../query/types.ts';
import { formActionSlice } from '../../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { PeopleDropdownContainer } from '../../PeopleDropdown/PeopleDropdownContainer.tsx';
import { ActionQuestionsContainer } from './ActionQuestionsContainer.tsx';
import { ActionStatusDropdown } from './ActionStatusDropdown.tsx';
import { ActionTabStripContainer } from './ActionTabStripContainer.tsx';
import { ActionVerificationStatusDropdown } from './ActionVerificationStatusDropdown.tsx';

export const ActionDetailV2Container: React.FC = () => {
  const {
    actionLabel,
    assignedTo,
    clearIssue,
    computedStatusChange,
    computedVerificationStatusChange,
    description,
    draftStatus,
    draftVerificationStatus,
    dueBy,
    errorAssignedTo,
    errorDescription,
    errorDueBy,
    errorNote,
    errorVerificationNotes,
    formLabel,
    hasAdditionalActionsTemplate,
    initialStatus,
    initialVerificationStatus,
    instanceIssues,
    instanceSubject,
    isNew,
    isOpen,
    isSpinning,
    issue,
    isUpdateStatusEnabled,
    module,
    note,
    noteLabel,
    permissions,
    showVerificationFields,
    updateAssignedTo,
    updateDescription,
    updateDueBy,
    updateIssue,
    updateNote,
    updateStatus,
    updateVerificationNotes,
    updateVerificationStatus,
    verificationNotes,
  } = useActionFields();

  const dateContext = useDateContext();
  const minimumDueBy = todayInTenantTz(dateContext.tenantTimezone);

  const issueSelections = useMemo(() => {
    if (!instanceIssues?.length) return [];
    return instanceIssues
      .map(toIssueSelection)
      .concat(getIssueEmptySelection(formLabel));
  }, [formLabel, instanceIssues]);

  const issueSelectionValue = useMemo(() => {
    if (!instanceIssues?.length) return;
    return issue ? toIssueSelection(issue) : getIssueEmptySelection(formLabel);
  }, [instanceIssues, issue, formLabel]);

  const handleIssueChanged = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      if (!to) return;
      if (to.id === 'N/A') return clearIssue();

      const instanceIssue = instanceIssues?.find((i) => i.id === to.id);
      if (!instanceIssue) return;

      updateIssue(instanceIssue);
    },
    [clearIssue, instanceIssues, updateIssue],
  );

  const canShowIssueField = getCanShowIssueField(
    module,
    instanceIssues,
    permissions,
    issueSelectionValue,
  );

  const elDetailsGroup = (
    <div css={styles.fieldGroup}>
      <div css={styles.leftColumn}>
        <TextField
          id={'description'}
          dataTest={'forms-actionDialog-descriptionField'}
          value={description}
          label={'Description'}
          onChange={updateDescription}
          multiline={true}
          maxRows={7}
          isEnabled={permissions?.edit}
          error={errorDescription}
        />
        {canShowIssueField && (
          <TextFieldDropdown
            id={'issue'}
            label={'Issue'}
            isEnabled={permissions?.edit}
            selections={issueSelections}
            onChange={handleIssueChanged}
            value={issueSelectionValue}
          />
        )}
      </div>
      <div css={styles.rightColumnDetailsGroup}>
        <IconWrapper icon={'notifications'}>
          <DatePicker
            id={'dueDate'}
            dataTest={'forms-actionDialog-dueDatePicker'}
            isEnabled={permissions?.edit}
            value={dueBy}
            onChange={updateDueBy}
            label={'Due Date'}
            minDate={minimumDueBy}
            error={errorDueBy}
          />
        </IconWrapper>
        <IconWrapper icon={'face'}>
          <PeopleDropdownContainer
            id={'actionAssignedTo'}
            error={errorAssignedTo}
            isEnabled={isOpen}
            label={'Assigned To'}
            onPersonSelected={updateAssignedTo}
            selectedPerson={assignedTo}
            source={'ACTION_PLAN'}
            subject={instanceSubject}
          />
        </IconWrapper>
      </div>
    </div>
  );

  const elStatusGroup = (
    <div css={styles.fieldGroup}>
      <div css={styles.leftColumn}>
        <TextField
          id={'note'}
          value={note}
          label={noteLabel}
          onChange={updateNote}
          multiline={true}
          maxRows={7}
          isEnabled={isOpen}
          error={errorNote}
        />
      </div>
      <div css={errorNote ? styles.rightColumnEndError : styles.rightColumnEnd}>
        <ActionStatusDropdown
          actionLabel={actionLabel}
          computedStatusChange={computedStatusChange}
          draftStatus={draftStatus}
          initialStatus={initialStatus}
          onChange={updateStatus}
          permissions={permissions}
          isEnabled={isUpdateStatusEnabled}
        />
      </div>
    </div>
  );

  const elVerificationGroup = showVerificationFields && (
    <div css={styles.fieldGroup}>
      <div css={styles.leftColumn}>
        <TextField
          id={'verificationNotes'}
          value={verificationNotes}
          label={'Verification Notes'}
          onChange={updateVerificationNotes}
          multiline={true}
          maxRows={7}
          isEnabled={permissions?.verify}
          error={errorVerificationNotes}
        />
      </div>
      <div
        css={
          errorVerificationNotes
            ? styles.rightColumnEndError
            : styles.rightColumnEnd
        }
      >
        <ActionVerificationStatusDropdown
          actionLabel={actionLabel}
          computedStatusChange={computedVerificationStatusChange}
          draftStatus={draftVerificationStatus}
          initialStatus={initialVerificationStatus}
          onChange={updateVerificationStatus}
          permissions={permissions}
          isEnabled={permissions?.verify && initialStatus === draftStatus}
        />
      </div>
    </div>
  );

  return (
    <div css={isSpinning ? [styles.base, styles.spinning] : styles.base}>
      {elDetailsGroup}
      {hasAdditionalActionsTemplate && <ActionQuestionsContainer />}
      {elStatusGroup}
      {elVerificationGroup}
      {!isNew && <ActionTabStripContainer />}
    </div>
  );
};

const useActionFields = () => {
  const dispatch = useFormsDispatch();
  const dateContext = useDateContext();

  return {
    actionLabel: useFormsSelector(
      (state) => state.tenantState.tenant.locale.label.action,
    ),
    noteLabel: useFormsSelector(
      (state) => state.tenantState.tenant.locale.label.actionNote,
    ),
    isOpen: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? ['Open', 'Overdue'].includes(state.formActionV2.existingAction.status)
        : true,
    ),
    description: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.description ??
        state.formActionV2.existingAction?.description ??
        '',
    ),
    errorDescription: useFormsSelector(
      (state) => state.formActionV2.errors.action.description,
    ),
    updateDescription: useCallback(
      (to: string) => dispatch(formActionSlice.updateDescription({ to })),
      [dispatch],
    ),
    note: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.note ??
        state.formActionV2.existingAction?.note ??
        '',
    ),
    errorNote: useFormsSelector(
      (state) => state.formActionV2.errors.action.note,
    ),
    updateNote: useCallback(
      (to: string) => dispatch(formActionSlice.updateNote({ to })),
      [dispatch],
    ),
    dueBy: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.dueBy ??
        state.formActionV2.existingAction?.dueBy,
    ),
    errorDueBy: useFormsSelector(
      (state) => state.formActionV2.errors.action.dueBy,
    ),
    updateDueBy: useCallback(
      (to: string) =>
        dispatch(
          formActionSlice.updateDueBy({
            to,
            timezone: dateContext.tenantTimezone,
          }),
        ),
      [dateContext.tenantTimezone, dispatch],
    ),
    permissions: useFormsSelector((state) => state.formActionV2.permissions),
    computedStatusChange: useFormsSelector(
      (state): FormActionStatusWithOverdue => {
        const draftVerificationStatus =
          state.formActionV2.draftChanges.action.verificationStatus;
        return draftVerificationStatus === 'Returned' ? 'Open' : undefined;
      },
    ),
    initialStatus: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? state.formActionV2.existingAction.status
        : state.formActionV2.draftChanges.action.status,
    ),
    draftStatus: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.status ??
        state.formActionV2.existingAction?.status,
    ),
    updateStatus: useCallback(
      (to: FormActionStatus) => dispatch(formActionSlice.updateStatus({ to })),
      [dispatch],
    ),
    assignedTo: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.assignedTo ??
        state.formActionV2.existingAction?.assignedTo,
    ),
    errorAssignedTo: useFormsSelector(
      (state) => state.formActionV2.errors.action.assignedTo,
    ),
    updateAssignedTo: useCallback(
      (to: PersonSelection) =>
        dispatch(
          formActionSlice.updateAssignedTo({
            to: { id: to.id, name: `${to.firstName} ${to.lastName}` },
          }),
        ),
      [dispatch],
    ),
    instanceSubject: useFormsSelector(
      (state) => state.formActionV2.formInstanceSubject,
    ),
    verificationNotes: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.verificationNotes ??
        state.formActionV2.existingAction?.verificationNotes ??
        '',
    ),
    errorVerificationNotes: useFormsSelector(
      (state) => state.formActionV2.errors.action.verificationNotes,
    ),
    updateVerificationNotes: useCallback(
      (to: string) => dispatch(formActionSlice.updateVerificationNotes({ to })),
      [dispatch],
    ),
    initialVerificationStatus: useFormsSelector((state) =>
      state.formActionV2.existingAction
        ? state.formActionV2.existingAction.verificationStatus
        : state.formActionV2.draftChanges.action.verificationStatus,
    ),
    draftVerificationStatus: useFormsSelector(
      (state) =>
        state.formActionV2.draftChanges.action.verificationStatus ??
        state.formActionV2.existingAction?.verificationStatus,
    ),
    computedVerificationStatusChange: useFormsSelector(
      (state): FormActionVerificationStatus => {
        const existingVerificationStatus =
          state.formActionV2.existingAction?.verificationStatus;
        if (!existingVerificationStatus) return;

        const draftStatus = state.formActionV2.draftChanges.action.status;
        if (!draftStatus) return;

        if (existingVerificationStatus === 'Recheck' && draftStatus === 'Open')
          return 'Returned';

        if (
          existingVerificationStatus === 'Returned' &&
          draftStatus === 'Completed'
        )
          return 'Recheck';
      },
    ),
    updateVerificationStatus: useCallback(
      (to: FormActionVerificationStatus) =>
        dispatch(formActionSlice.updateVerificationStatus({ to })),
      [dispatch],
    ),
    showVerificationFields: useFormsSelector((state) => {
      const existingAction = state.formActionV2.existingAction;
      if (!existingAction) return false;

      const canVerify = state.formActionV2.permissions?.verify;
      if (canVerify) return true;

      const verificationStatus = existingAction.verificationStatus;
      if (!verificationStatus) return false;

      return ['Unverified', 'Excluded'].includes(verificationStatus)
        ? false
        : true;
    }),
    isUpdateStatusEnabled: useFormsSelector((state) => {
      const existingAction = state.formActionV2.existingAction;
      if (!existingAction) return true;

      if (!state.formActionV2.permissions?.verify) return true;

      const draftChanges = state.formActionV2.draftChanges;
      return !draftChanges.action.verificationStatus;
    }),
    isNew: useFormsSelector((state) => !state.formActionV2.existingAction),
    issue: useFormsSelector((state) => {
      // Set to cleared.
      if (state.formActionV2.draftChanges.action.issue === null) return;

      return (
        state.formActionV2.draftChanges.action.issue ??
        state.formActionV2.existingAction?.issue
      );
    }),
    clearIssue: useCallback(
      () => dispatch(formActionSlice.clearIssue()),
      [dispatch],
    ),
    updateIssue: useCallback(
      (to: FormInstanceIssue) => dispatch(formActionSlice.updateIssue({ to })),
      [dispatch],
    ),
    instanceIssues: useFormsSelector(
      (state) => state.formActionV2.instanceIssues,
    ),
    formLabel: useFormsSelector((state) =>
      state.tenantState.tenant.locale.label.form.toLowerCase(),
    ),
    module: useFormsSelector((state) => state.tenantState.tenant.module),
    isSpinning: useFormsSelector(
      (state) => state.formActionV2.isLoading || state.formActionV2.isSaving,
    ),
    hasAdditionalActionsTemplate: useFormsSelector((state) =>
      Boolean(state.formActionV2.actionTemplate),
    ),
  };
};

const getCanShowIssueField = (
  module: ModuleType,
  instanceIssues?: FormInstanceIssue[],
  permissions?: formActionSlice.FormActionPermissions,
  issueSelectionValue?: ISelectionListItem<IListItemLabel>,
) => {
  if (module !== 'compliance') return false;
  if (!instanceIssues?.length) return false;
  if (permissions?.edit) return true;
  if (!issueSelectionValue) return false;
  if (issueSelectionValue.id === 'N/A') return false;

  return true;
};

const toIssueSelection = (
  issue: FormInstanceIssue,
): ISelectionListItem<IListItemLabel> => {
  if (!issue) return;

  const coachingRequirementColor =
    issue.issueCoachingRequired === true
      ? COLORS.COACHING_REQUIREMENT.MANDATORY
      : issue.issueCoachingRequired === false
        ? COLORS.COACHING_REQUIREMENT.RECOMMENDED
        : COLORS.COACHING_REQUIREMENT.NONE;

  return {
    id: issue.id,
    content: {
      text: issue.label,
      description: issue.formSectionName,
    },
    icon: 'localLibrary',
    iconColor: {
      default: coachingRequirementColor,
      selected: coachingRequirementColor,
    },
  };
};

const getIssueEmptySelection = (
  formLabel: string,
): ISelectionListItem<IListItemLabel> => ({
  id: 'N/A',
  content: {
    text: 'No linked issue',
    description: `Not related to any issues on this ${formLabel}`,
  },
  icon: 'clear',
  iconColor: { default: '#a0a0a0', selected: '#a0a0a0' },
});

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: 5,
  }),
  spinning: css({
    pointerEvents: 'none',
  }),
  fieldGroup: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    margin: -10,
    padding: 10,
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
  }),
  leftColumn: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    flex: '1 1 65%',
    marginBottom: 7.5,
  }),
  rightColumnDetailsGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: '1 1 35%',
  }),
  rightColumnEnd: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 18,
    flex: '1 1 35%',
  }),
  rightColumnEndError: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 18,
    flex: '1 1 35%',
    paddingBottom: 25,
  }),
};
