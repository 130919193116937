/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { useUtilDispatch } from '@seeeverything/ui.util/src/redux/store.ts';
import { init, last, reduce } from 'ramda';
import React, { useCallback, useMemo } from 'react';
import {
  Grid,
  GridClickEventHandler,
  GridSelectionChangedEventHandler,
  IGridRow,
  IRenderGridCellArgs,
} from '../Grid/index.ts';
import { IIcon } from '../Icon/types.ts';
import { Cell } from './components/Cell.tsx';
import { HeaderCell } from './components/HeaderCell.tsx';
import { LoadMoreButton } from './components/LoadMoreButton.tsx';
import { CellTheme, IDataGridCellTheme, getCellTheme } from './themes.ts';
import {
  IDataGridCellThemeArgs,
  IDataGridColumn,
  IDataGridGroupCount,
  IDataGridHeaderGroup,
  RenderDataGridCell,
} from './types.ts';

const LOAD_MORE_ROW: IGridRow = {
  id: 'load-more',
  data: [],
  isSelectable: false,
};

const NO_DATA_ROW: IGridRow = {
  id: 'no-data',
  data: [],
  isSelectable: false,
};

const ROW_HEIGHT = {
  HEADER: 40,
  BODY_LARGE: 37,
  BODY_MEDIUM: 27,
  NO_DATA: 60,
};

export interface IDataGridProps<
  T extends IGridRow = IGridRow,
  V extends object = undefined,
> {
  cellTheme?: (args: IDataGridCellThemeArgs) => CellTheme | undefined;
  cellThemeOverrides?: (
    args: IRenderGridCellArgs,
  ) => IDataGridCellTheme | undefined;
  columns: IDataGridColumn[];
  data: IGridRow<V>[];
  emptyMessage?: string;
  footer?: IGridRow | false;
  getHeaderIcon?: (columnId: string) => IIcon;
  hasEmptyMessage?: boolean;
  hasExpandButton?: boolean;
  hasNextPage?: boolean;
  headerBorder?: string;
  headerBorderHeavy?: string;
  headerCellFontSize?: string | number;
  headerGroups?: IDataGridHeaderGroup[];
  id: string;
  isLoading?: boolean | 'MORE';
  isScrollable?: boolean;
  isVirtualized?: boolean;
  loadMoreIcon?: string;
  loadMoreText?: string;
  onClick?: GridClickEventHandler;
  onDoubleClick?: GridClickEventHandler;
  onHeaderClick?: GridClickEventHandler;
  onLoadMore?: () => void;
  onLoadMoreInView?: () => void;
  onMouseDown?: GridClickEventHandler;
  onSelectionChanged?: GridSelectionChangedEventHandler;
  paddingStyle?: 'LARGE' | 'MEDIUM';
  renderCellContents?: RenderDataGridCell<T>;
  renderHeaderPopup?: (columnId: string) => React.ReactNode;
}

/**
 * A higher-level component displaying tabular data with header & user interaction.
 */
export const DataGrid = <
  T extends IGridRow = IGridRow,
  V extends object = undefined,
>({
  cellTheme,
  cellThemeOverrides,
  columns,
  data,
  emptyMessage,
  footer,
  getHeaderIcon,
  hasEmptyMessage,
  hasExpandButton = true,
  hasNextPage,
  headerBorder,
  headerBorderHeavy,
  headerCellFontSize,
  headerGroups = [],
  id,
  isLoading,
  isScrollable = false,
  isVirtualized,
  loadMoreIcon,
  loadMoreText,
  onClick,
  onDoubleClick,
  onHeaderClick,
  onLoadMore,
  onLoadMoreInView,
  onMouseDown,
  onSelectionChanged,
  paddingStyle = 'MEDIUM',
  renderCellContents,
  renderHeaderPopup,
}: IDataGridProps<T, V>) => {
  const dispatch = useUtilDispatch();
  const hasData = Boolean(data.length);

  const rowHeight = useMemo(
    () =>
      hasData
        ? paddingStyle === 'LARGE'
          ? ROW_HEIGHT.BODY_LARGE
          : ROW_HEIGHT.BODY_MEDIUM
        : ROW_HEIGHT.NO_DATA,
    [hasData, paddingStyle],
  );

  const columnGroups = useMemo(() => getColumnGroups(columns), [columns]);
  const headerRows = useMemo(
    () => getHeaderData(columns, columnGroups),
    [columnGroups, columns],
  );
  const bodyTop = ROW_HEIGHT.HEADER * headerRows.length;
  const hasGroups = Boolean(headerGroups.length);

  const canLoadMore = useMemo(
    () => Boolean(onLoadMore && (hasNextPage || (!footer && hasExpandButton))),
    [footer, hasExpandButton, hasNextPage, onLoadMore],
  );

  const showLoadMore = useMemo(
    () => canLoadMore || isLoading === 'MORE',
    [canLoadMore, isLoading],
  );

  const isEmpty = useMemo(
    () => hasEmptyMessage !== false && data.length === 0,
    [data.length, hasEmptyMessage],
  );

  const isFooterRow = useCallback(
    (index: number) => {
      const footerIndex = showLoadMore ? data.length + 1 : data.length;
      return footer && index === footerIndex;
    },
    [data.length, footer, showLoadMore],
  );

  const isLoadMoreRow = useCallback(
    (index: number) => showLoadMore && index === data.length,
    [data.length, showLoadMore],
  );

  const loadMoreColumnSpanning = useCallback(
    (rowIndex: number, columnIndex: number) =>
      (isLoadMoreRow(rowIndex) || isEmpty) && columnIndex === 0
        ? columns.length
        : 0,
    [columns?.length, isEmpty, isLoadMoreRow],
  );

  const renderCell = useCallback(
    (args: IRenderGridCellArgs<T>) => {
      const { row, rowIndex, columnIndex } = args;

      if (isLoadMoreRow(rowIndex))
        return (
          <LoadMoreButton
            onClick={onLoadMore}
            onInView={onLoadMoreInView}
            isLoading={isLoading === 'MORE'}
            size={paddingStyle}
            text={loadMoreText}
            icon={loadMoreIcon}
            dataTest={'primitives-dataGrid-loadMoreButton'}
          />
        );

      const theme = isFooterRow(rowIndex)
        ? 'footer'
        : cellTheme?.({ rowIndex, columnIndex }) || 'base';

      const cellData =
        renderCellContents?.({ ...args, getCellTheme, theme }) ||
        row.data[columnIndex];

      const themeOverrides = cellThemeOverrides?.({ ...args });

      return (
        <Cell
          key={`${row.id}-${columnIndex}`}
          data={cellData}
          column={columns[columnIndex]}
          size={paddingStyle}
          theme={theme}
          themeOverrides={themeOverrides}
          {...args}
        />
      );
    },
    [
      isLoadMoreRow,
      onLoadMore,
      onLoadMoreInView,
      isLoading,
      paddingStyle,
      loadMoreText,
      loadMoreIcon,
      isFooterRow,
      cellTheme,
      renderCellContents,
      cellThemeOverrides,
      columns,
    ],
  );

  const handleHeaderCellClicked = useCallback(
    (groupDefinition: IDataGridHeaderGroup) => () => {
      const scrollToId = groupDefinition?.linkableHeader.scrollToId;
      if (!scrollToId) return;

      dispatch(
        scrollSlice.scrollToDataId({
          scrollContainerId: 'scrollPanel',
          dataId: groupDefinition.linkableHeader.scrollToId,
        }),
      );
    },
    [dispatch],
  );

  const renderHeaderCell = useCallback(
    (args: IRenderGridCellArgs) => {
      const { row, columnIndex, isFirstRow, isLastRow, isLastColumn } = args;

      const isHidden = row.data.length - 1 < columnIndex;
      if (isHidden) return undefined;

      const cellValue = row.data[columnIndex];

      const groupDefinition = headerGroups.find(
        (group) => group.groupId === cellValue,
      );

      const isLink = Boolean(groupDefinition?.linkableHeader);

      const column = columns[columnIndex];

      return (
        <HeaderCell
          border={headerBorder}
          borderHeavy={headerBorderHeavy}
          capitalized={hasGroups && isFirstRow}
          column={column}
          displayValue={cellValue}
          fontSize={headerCellFontSize}
          getHeaderIcon={getHeaderIcon}
          gridId={id}
          renderHeaderPopup={renderHeaderPopup}
          isFirstRow={isFirstRow}
          isLastColumn={isLastColumn}
          isLastRow={isLastRow}
          key={`${row.id}-${columnIndex}`}
          onClick={
            isLink ? handleHeaderCellClicked(groupDefinition) : undefined
          }
        />
      );
    },
    [
      columns,
      getHeaderIcon,
      handleHeaderCellClicked,
      hasGroups,
      headerBorder,
      headerBorderHeavy,
      headerCellFontSize,
      headerGroups,
      id,
      renderHeaderPopup,
    ],
  );

  const bodyData = useMemo(
    () =>
      isEmpty
        ? [NO_DATA_ROW]
        : [
            ...data,
            ...(showLoadMore ? [LOAD_MORE_ROW] : []),
            ...(footer ? [footer] : []),
          ],
    [data, footer, isEmpty, showLoadMore],
  );

  const computedStyles = {
    body: css({
      position: 'absolute',
      top: bodyTop,
      right: 0,
      left: 0,
      bottom: 0,
    }),
  };

  return (
    <div css={isScrollable ? styles.outerScrollable : styles.outerInline}>
      <Grid
        canHighlight={false}
        data={headerRows}
        cellColumnSpanning={groupColumnSpanning(columnGroups)}
        columns={columns}
        rowHeight={ROW_HEIGHT.HEADER}
        renderCell={renderHeaderCell}
        onClick={onHeaderClick}
      />
      <div css={isScrollable ? computedStyles.body : undefined}>
        {isEmpty && !isLoading && (
          <div css={styles.empty}>
            <Text size={14} color={color.format(-0.2)} italic={true}>
              {isLoading ? '' : emptyMessage}
            </Text>
          </div>
        )}
        {!isEmpty && (
          <Grid
            canHighlight={true}
            data={bodyData}
            cellColumnSpanning={loadMoreColumnSpanning}
            columns={columns}
            rowHeight={rowHeight}
            renderCell={renderCell}
            isScrollable={isScrollable}
            isVirtualized={isVirtualized}
            onClick={hasData ? onClick : undefined}
            onDoubleClick={hasData ? onDoubleClick : undefined}
            onMouseDown={hasData ? onMouseDown : undefined}
            onSelectionChanged={onSelectionChanged}
          />
        )}
      </div>
    </div>
  );
};

export function getHeaderData<V extends object = undefined>(
  columns: IDataGridColumn[],
  groups: IDataGridGroupCount[],
): IGridRow<V>[] {
  const groupRow = groups.length && {
    id: 'groups',
    isSelectable: false,
    data: groups.map((group) => group.group),
  };

  const headerRow = {
    id: 'header',
    isSelectable: false,
    data: columns.map((col) => col.label),
  };

  return groupRow ? [groupRow, headerRow] : [headerRow];
}

/**
 * Returns an array of objects describing each group in the supplied columns.
 * Columns without a group are returned individually with undefined name.
 * @param columns The columns to check for groups.
 * @return {Array<{ group: string; count: number }>} The groups.
 */
export function getColumnGroups(columns: IDataGridColumn[]) {
  const visibleColumns = columns.filter((column) => !column.isHidden);
  const groups = reduce<IDataGridColumn, IDataGridGroupCount[]>(
    (acc, column) => {
      const { group } = column;
      const prev = last(acc);
      return prev && group && group === prev.group // No-group returned individually.
        ? ([
            ...init(acc),
            { group, count: prev.count + 1 },
          ] as IDataGridGroupCount[])
        : ([...acc, { group, count: 1 }] as IDataGridGroupCount[]);
    },
    [],
    visibleColumns,
  );
  // When no columns have a group we don't need to return values.
  return groups.some((group: IDataGridGroupCount) => Boolean(group.group))
    ? groups
    : [];
}

const groupColumnSpanning = (groups: IDataGridGroupCount[]) => {
  if (groups.length === 0) return undefined;

  const spanning = groups.reduce(
    (acc, group) => [
      ...acc,
      group.count - 1,
      ...Array(group.count - 1).fill(0),
    ],
    [],
  );

  return (rowIndex: number, columnIndex: number) => {
    if (rowIndex !== 0) return 0;

    return spanning[columnIndex];
  };
};

const styles = {
  empty: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: ROW_HEIGHT.NO_DATA,
  }),
  outerInline: css({
    position: 'relative',
  }),
  outerScrollable: css({
    position: 'absolute',
    inset: 0,
  }),
};
