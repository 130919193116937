import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IEntityConfigs } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import {
  TenantConfiguration,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { businessEntities } from '../../data/data.businessEntities.ts';

/**
 * Gets the business units (as entities) from data.
 */

/**
 * Gets the business entities. (i.e. people, teams, etc.).
 * Filtered by BU if specified.
 * Used in the Query Builder.
 */
export function getBusinessEntities(
  locale: TenantLocale,
  chipType?: string,
): IEntityConfigs {
  const allBusinessEntities = businessEntities(chipType) ?? [];
  return allBusinessEntities
    .map((businessEntity) => {
      switch (businessEntity) {
        case 'People':
          return {
            [str.camelize(businessEntity)]: {
              label: businessEntity,
              icon: Icons.face,
            },
          };

        case 'Forms':
          return {
            [str.camelize(businessEntity)]: {
              label: str.pluralize.plural(locale.label.form),
              icon: Icons.clipboardList,
            },
          };

        case 'Virtual':
          return {
            [str.camelize(businessEntity)]: {
              label: businessEntity,
              icon: Icons.group,
            },
          };

        case 'Team':
          return {
            [str.camelize(businessEntity)]: {
              label: locale.label.team
                .split('/')
                .map((s) => str.pluralize.plural(s))
                .join('/'), // This supports the 'Team/Branch' option of team locale.
              icon: Icons.group,
            },
          };

        case 'Business Unit':
        case 'Organization':
          // Note: we want to filter out business units and orgs from showing up so just ignore these.
          return {};

        default:
          return {
            [str.camelize(businessEntity)]: {
              label: businessEntity,
              icon: Icons.addBox,
            },
          };
      }
    })
    .reduce((a, v) => ({ ...a, ...v }), {});
}

/**
 * Gets the enabled modules. (i.e. compliance, coaching).
 * Used in the Query Builder.
 */
export const getModules = (
  tenantConfig: TenantConfiguration,
): IEntityConfigs => {
  return Object.keys(tenantConfig.modules || {})
    .filter((module: ModuleType) => tenantConfig.modules[module].isEnabled)
    .map((module: ModuleType) => ({
      [module]: {
        label: tenantConfig.modules[module].label,
        icon: Icons[tenantConfig.modules[module].icon],
      },
    }))
    .reduce((a, v) => ({ ...a, ...v }), {});
};
