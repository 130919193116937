/** @jsxImportSource @emotion/react */
import {
  DataGrid,
  DataGridClickThroughBehavior,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  IDataGridClickThroughProps,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';

import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { formBulkUploadClickThroughSlice } from '../../redux/formBulkUploadClickThrough/index.ts';
import { formBulkUploadJobHistorySlice } from '../../redux/formBulkUploadJobHistory/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { BulkUploadColumns } from './types.ts';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridClickThroughBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridProps &
    IDataGridClickThroughProps &
    IDataGridInfiniteScrollBehaviorProps
>;

/**
 * A table of Bulk Upload Jobs.
 */
export const BulkUploadJobsGrid: React.FC = () => {
  const dispatch = useFormsDispatch();

  const momentTz = useMomentTenantTimezone();

  const jobs = useFormsSelector((state) => state.formBulkUploadJobHistory.jobs);

  const isLoading = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.isLoading,
  );

  const isLoadingMore = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.isLoadingMore,
  );

  const hasNextPage = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.hasNextPage,
  );

  const loadedPage = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.currentPage,
  );

  const handleClickThroughClicked = useCallback(
    (rowId: string, columnId: BulkUploadColumns) => {
      if (columnId === BulkUploadColumns.FAILED)
        dispatch(
          formBulkUploadClickThroughSlice.loadFailedClickThrough({
            jobId: rowId,
          }),
        );
      if (columnId === BulkUploadColumns.SUCCEEDED)
        dispatch(
          formBulkUploadClickThroughSlice.loadSucceededClickThrough({
            jobId: rowId,
          }),
        );
    },
    [dispatch],
  );

  const handleLoadMore = useCallback(
    () =>
      dispatch(formBulkUploadJobHistorySlice.loadJobs({ loadNextPage: true })),
    [dispatch],
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const data = useMemo<IGridRow[]>(
    () =>
      jobs.map((job) => ({
        id: job.id,
        data: [
          momentTz(job.uploadedAt).format('D MMM YY, h:mm a').toString(),
          job.templateNames,
          job.uploadedBy.name,
          str.humanize(job.jobStatus, true),
          job.succeeded,
          job.failed,
        ],
        canClickThrough: job.jobStatus !== 'Processing',
      })),
    [jobs, momentTz],
  );

  return (
    <Grid
      id={'BulkUploadJobs'}
      clickThroughColumnIds={CLICK_THROUGH_COLUMNS}
      onClickThroughColumnClick={handleClickThroughClicked}
      isLoading={isLoading}
      isScrollable={true}
      isVirtualized={true}
      columns={createColumns(formLabel)}
      data={data}
      onLoadPage={handleLoadMore}
      isLoadingPage={isLoadingMore}
      loadedPage={loadedPage}
      loadMoreText={'Load more'}
      hasNextPage={hasNextPage}
    />
  );
};

const CLICK_THROUGH_COLUMNS = [
  BulkUploadColumns.SUCCEEDED,
  BulkUploadColumns.FAILED,
];
const createColumns = (formLabel: string): IDataGridColumn[] => [
  {
    id: BulkUploadColumns.UPLOADED_AT,
    label: 'Uploaded Date',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.TEMPLATE_NAMES,
    label: `${formLabel} Types`,
    width: '5*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.UPLOADED_BY,
    label: 'Uploaded By',
    width: '3*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.JOB_STATUS,
    label: 'Status',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.SUCCEEDED,
    label: 'Created',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.FAILED,
    label: 'Failed',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
];
