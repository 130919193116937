/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useState } from 'react';
import { uniqWith } from 'ramda';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { useDebounce } from '@seeeverything/ui.primitives/src/hooks/useDebounce.ts';
import { useFormsSelector } from '../../redux/store.ts';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import {
  PersonSubject,
  TeamSubject,
  PersonSelection,
} from '../../query/types.ts';
import { usePeopleQuery } from '../../query/usePeopleQuery.ts';
import { usePersonQuery } from '../../query/usePersonQuery.ts';
import { useSubjectPersonQuery } from '../../query/useSubjectPersonQuery.ts';
import { useSubjectTeamQuery } from '../../query/useSubjectTeamQuery.ts';

export interface IPeopleDropdownContainerProps {
  error?: string;
  id: string;
  isEnabled: boolean;
  label: string;
  onPersonSelected: (to: PersonSelection) => void;
  selectedPerson?: { id: string; name: string };
  source: 'ACTION_PLAN' | 'FORM_INSTANCE';
  subject?: PersonSubject | TeamSubject;
}

export const PeopleDropdownContainer: React.FC<
  IPeopleDropdownContainerProps
> = ({
  error,
  id,
  isEnabled,
  label,
  onPersonSelected,
  selectedPerson,
  subject,
}) => {
  const { batchClient } = useGraphQL();
  const [input, setInput] = useState('');
  const [inputDebounce, setInputImmediate] = useDebounce(input, 250);

  const { track } = useSegmentAnalytics();

  const handleInputChange = useCallback(
    (to: string, reason: 'reset' | 'input') => {
      if (reason === 'reset' || to === '') {
        setInputImmediate('');
        return;
      }

      setInput(to);
    },
    [setInputImmediate],
  );

  const selectedPersonContent = useMemo(
    () => (selectedPerson ? toSelectedItem(selectedPerson) : undefined),
    [selectedPerson],
  );

  const handlePersonSelected = useCallback<
    (to: ISelectionListItem<IListItemLabel, PersonSelection>) => void
  >(
    (e) => {
      const value = e.value;
      if (!value) return;

      if (value.id === selectedPerson?.id) return;

      const personId = value.id;
      track('person_dropdown_selected', {
        selectedPersonId: personId,
        subjectId: subject?.id,
        subjectType: subject?.type,
        subjectRelation: subject ? value.type : undefined,
      });

      onPersonSelected(value);
    },
    [onPersonSelected, selectedPerson?.id, subject, track],
  );

  const instanceLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const subjectLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.formSubject,
  );

  const authenticatedUserId = useFormsSelector(
    (state) => state.tenantState.tenant.authenticatedUser?.id,
  );
  const personAuthUser = usePersonQuery(
    batchClient,
    `Related to ${instanceLabel}`,
    'SELF',
    authenticatedUserId,
    'Myself',
  );

  const personSubject = usePersonQuery(
    batchClient,
    `Related to ${instanceLabel}`,
    'SUBJECT',
    subject?.type === 'PERSON' ? subject.id : undefined,
    subjectLabel,
  );

  const personSelected = usePersonQuery(
    batchClient,
    'Assigned To',
    'OTHER',
    selectedPerson?.id,
  );

  const { subjectPersonManagers, isFetchingSubjectPersonManagers } =
    useSubjectPersonQuery(batchClient, instanceLabel, subject);

  const { subjectTeamManagers, isFetchingSubjectTeamManagers } =
    useSubjectTeamQuery(batchClient, instanceLabel, subject);

  const { people, isFetchingPeople } = usePeopleQuery(batchClient, {
    inputFilter: inputDebounce,
    category: 'Other People',
    isEnabled: true,
  });

  const isLoading =
    isFetchingPeople ||
    isFetchingSubjectPersonManagers ||
    isFetchingSubjectTeamManagers ||
    personSubject.isFetching ||
    personAuthUser.isFetching ||
    personSelected.isFetching;

  const peopleList = useMemo(() => {
    if (!people) return;

    // Filter out the "prominent" people from the list and put them at the top.
    const idsSortedToTop = [
      subjectPersonManagers?.map(
        (subjectPersonManager) => subjectPersonManager.id,
      ),
      subjectTeamManagers?.map((subjectTeamManager) => subjectTeamManager.id),
      personSubject.data?.id,
      personAuthUser.data?.id,
      personSelected.data?.id,
    ]
      .flat()
      .filter(Boolean);

    const selections = people.filter(
      (person) => !idsSortedToTop.includes(person.id as string),
    );

    const firstSelections = [
      subjectPersonManagers,
      subjectTeamManagers,
      personSubject.data,
      personSelected.data,
      personAuthUser.data,
    ]
      .flat()
      .filter(Boolean);

    if (firstSelections.length) selections.unshift(...firstSelections);

    // Remove duplicates, keeping the first occurrence where duplicated.
    return uniqWith((a, b) => a.id === b.id, selections);
  }, [
    subjectPersonManagers,
    subjectTeamManagers,
    people,
    personAuthUser.data,
    personSelected.data,
    personSubject.data,
  ]);

  return (
    <TextFieldDropdown
      id={id}
      dataTest={id}
      error={error}
      isEnabled={isEnabled}
      isLoading={isLoading}
      label={label}
      onChange={handlePersonSelected}
      onInputChanged={handleInputChange}
      selections={peopleList}
      shouldFilter={false}
      value={
        personSelected.isFetching ? selectedPersonContent : personSelected.data
      }
    />
  );
};

const toSelectedItem = (person: {
  id: string;
  name: string;
}): ISelectionListItem => ({
  id: person.id,
  content: { text: person.name },
});
