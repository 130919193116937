/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import * as R from 'ramda';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ChipKey,
  IChipData,
  IDropdown,
  IQuery,
  IQueryData,
  IQuerySelection,
  QueryFocusTarget,
} from '../../api/api.queryBuilder/types.ts';
import { DropdownContainer } from '../QueryBuilder.Dropdown/DropdownContainer.tsx';
import { serialize } from './common/serialization.ts';
import { handleBlur } from './common/state/actions.ts';
import { IconButtons } from './components/IconButtons/IconButtons.tsx';
import {
  ChipEditEventHandler,
  DropdownLocationChangeEvent,
  FormatChipEventHandler,
  IQueryBuilderInputRefProps,
  QueryBuilderInput,
  ShowDropdownEventHandler,
} from './components/QueryBuilderInput/index.ts';
import { IInternalSelectionState, IInternalState } from './types.ts';

export type QueryBuilderEvent = {
  query: IQuery;
  selection?: IQuerySelection;
  forceRefresh?: boolean;
  isCancelled: boolean;
  dropdown?: IDropdown;
};

export type QueryBuilderFocusEvent = {
  isFocused: boolean;
  focusTarget?: QueryFocusTarget;
};

export interface IQueryBuilderRefProps {
  focus: () => void;
  blur: () => void;
}

export interface IQueryBuilderProps {
  data: IQueryData;
  isEnabled?: boolean;
  onChanged?: (e: QueryBuilderEvent) => void;
  onChipRequestDropdown?: ShowDropdownEventHandler;
  onChipStartedEditing?: ChipEditEventHandler;
  onChipStoppedEditing?: ChipEditEventHandler;
  onClearClick?: () => void;
  onFocusChanged?: (e: QueryBuilderFocusEvent) => void;
  onFormatChip?: FormatChipEventHandler;
  state: IInternalState;
}

export interface IQueryBuilderState {
  focusTarget?: QueryFocusTarget;
  isFocused: boolean;
  inputState: IInternalState;
}

interface IPosition {
  left: number;
  top: number;
}

/**
 * The top level Query Builder component.
 * This component is responsible for:
 *    - rendering and maintaining the state of the QueryBuilderInput,
 *    - rendering and transforming state into a usable format for the autocomplete
 *    - coordinating focus between the input and the autocomplete
 */
const View: React.ForwardRefRenderFunction<
  IQueryBuilderRefProps,
  IQueryBuilderProps
> = (
  {
    data,
    isEnabled = true,
    onChanged,
    onChipRequestDropdown,
    onChipStartedEditing,
    onChipStoppedEditing,
    onClearClick,
    onFocusChanged,
    onFormatChip,
    state,
  },
  ref,
) => {
  const [measureRef, bounds] = useMeasure();
  const [focusTarget, setFocusTarget] = useState<QueryFocusTarget>();
  const [isFocused, setIsFocused] = useState(false);
  const [inputState, setInputState] = useState<IInternalState>(state);
  const [dropdownPosition, setDropdownPosition] = useState<IPosition>({
    left: 0,
    top: 0,
  });
  const [lastChangedArgs, setLastChangedArgs] = useState<QueryBuilderEvent>();
  const [ignoreFocusChange, setIgnoreFocusChange] = useState(false);

  const inputRef = useRef<IQueryBuilderInputRefProps>(null);

  useEffect(() => {
    setInputState(state);
  }, [state]);

  const handleFocus = useCallback(
    (toIsFocused: boolean, toFocusTarget?: QueryFocusTarget) => {
      if (ignoreFocusChange) return;

      if (!isEnabled) setIsFocused(false);
      if (!toIsFocused) setFocusTarget(undefined);

      const isChanged =
        toIsFocused !== isFocused || toFocusTarget !== focusTarget;

      setFocusTarget(toFocusTarget);
      setIsFocused(toIsFocused);

      if (toIsFocused && inputRef.current) {
        setTimeout(() => {
          setIgnoreFocusChange(true);
          inputRef.current.focus();
          setIgnoreFocusChange(false);
        }, 0);
      }

      if (!toIsFocused && !toFocusTarget && inputRef.current)
        inputRef.current.blur();

      if (isChanged)
        onFocusChanged?.({
          isFocused: toIsFocused,
          focusTarget: toFocusTarget,
        });
    },
    [focusTarget, ignoreFocusChange, isEnabled, isFocused, onFocusChanged],
  );

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (!inputRef.current.containsFocus()) handleFocus(true, 'INPUT');
    },
    blur: () => {
      handleFocus(false, undefined);
    },
  }));

  const handleInputFocus = useCallback(
    () => handleFocus(true, 'INPUT'),
    [handleFocus],
  );

  const handleRootClick = useCallback(() => {
    // Ensure that if the boundary of the query-builder is clicked, but the Input component
    // is missed, it behaves as though the Input was clicked by focusing it.
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, []);

  const handleInputClick = useCallback(() => {
    if (focusTarget !== 'INPUT') handleInputFocus();
  }, [focusTarget, handleInputFocus]);

  const findChipIndex = useCallback(
    (key?: ChipKey, nextState?: IInternalState) => {
      return key === undefined
        ? -1
        : (nextState ?? inputState).chips.findIndex((chip) => chip.key === key);
    },
    [inputState],
  );

  const findChip = useCallback(
    (key?: ChipKey, nextState?: IInternalState) => {
      const index = findChipIndex(key, nextState ?? inputState);
      const result = (nextState ?? inputState).chips[index];
      return result?.data;
    },
    [findChipIndex, inputState],
  );

  const toSelection = useCallback(
    (nextState: IInternalState) => {
      const selectedChipKey =
        nextState.selectedChip && nextState.selectedChip.key;
      const editingChipKey = nextState.editingChip && nextState.editingChip.key;

      const selectedChip = findChip(selectedChipKey, nextState);
      const editingChip = findChip(editingChipKey, nextState);

      if (!selectedChip && !editingChip) {
        return undefined;
      } else {
        const type = editingChip
          ? 'EDITING'
          : (nextState.selectedChip?.type as IInternalSelectionState['type']);
        const chipIndex = editingChip
          ? findChipIndex(editingChipKey, nextState)
          : findChipIndex(selectedChipKey, nextState);
        const selection: IQuerySelection = {
          type,
          chipIndex,
          chip: (editingChip || selectedChip) as IChipData,
        };
        return selection;
      }
    },
    [findChip, findChipIndex],
  );

  const handleInputChange = useCallback(
    (nextState: IInternalState) => {
      if (!isEnabled) {
        return;
      }

      // Request change data from business logic.
      let query = serialize(nextState);
      const selection = toSelection(nextState);
      const result = data.change({ action: 'NEXT_QUERY', query, selection });
      const isCancelled = result && result.isCancelled;

      if (!selection) {
        /*
         * The following is to address a use case where:
         *  User chooses a value on an editable chip;
         *  User then goes back to the same chip (making it editable),
         *   changes the `contenteditable` text to something different
         *   (not valid), then navigating away from the chip.
         *
         * The end result of this is that the chip text should be reverted
         * back to its previous state.
         */
        query.chips.forEach((chip, chipIndex) => {
          const previousChipData = state.chips.find(
            (previous) =>
              chip.value === previous.data.value &&
              chip.label !== previous.data.label,
          );

          if (previousChipData) {
            const previousChip = {
              ...query.chips[chipIndex],
              data: previousChipData,
            };

            // Update the query to the previous chip value...
            query = {
              ...query,
              chips: [
                ...query.chips.slice(0, chipIndex),
                previousChip,
                ...query.chips.slice(chipIndex + 1),
              ],
            };

            // ...and update the `nextState` to the previous chip value.
            nextState = {
              ...nextState,
              chips: [
                ...nextState.chips.slice(0, chipIndex),
                previousChipData,
                ...nextState.chips.slice(chipIndex + 1),
              ],
            };
          }
        });
      }

      /*
       * In some cases, updates to the QB don't fire through this handler.
       * Until this is resolved, workaround this by explicitly checking the
       * UI state of chips (length is sufficient) against the next state,
       * and force an update through.
       */
      const forceRefresh = state.chips.length !== nextState.chips.length;

      // Prepare args.
      const e: QueryBuilderEvent = {
        query,
        selection,
        isCancelled: result ? result.isCancelled : false,
        dropdown: result ? result.dropdown : undefined,
        forceRefresh,
      };

      // Update internal state.
      if (!isCancelled) setInputState(nextState);

      // Alert listeners.
      if (!isCancelled && onChanged) {
        // Ensure state has changed before firing.
        const isChanged =
          forceRefresh ||
          (lastChangedArgs
            ? !R.equals(lastChangedArgs.query, e.query) ||
              !R.equals(lastChangedArgs.selection, e.selection)
            : true);

        setLastChangedArgs(e);

        // Fire event.
        if (isChanged) onChanged(e);
      }
    },
    [data, isEnabled, lastChangedArgs, onChanged, state.chips, toSelection],
  );

  const handleHide = useCallback(() => {
    const nextState = handleBlur(inputState);
    handleInputChange(nextState);
    handleFocus(false, undefined); // Remove focus.
  }, [handleFocus, handleInputChange, inputState]);

  const handleCursorLocationChange = (e: DropdownLocationChangeEvent) => {
    const { position } = e;
    if (
      R.isNil(position.left) ||
      R.isNil(position.top) ||
      Number.isNaN(position.left) ||
      Number.isNaN(position.top)
    ) {
      return;
    }
    setDropdownPosition({
      left: Math.max(0, position.left),
      top: Math.max(0, position.top),
    });
  };

  const isStateEmpty = useMemo(() => {
    const serialized = serialize(inputState);
    return serialized.chips.length === 0 && serialized.filter.length === 0;
  }, [inputState]);

  const currentInputIsTextOnly = inputState.chips.length === 0;

  const styles = {
    base: css({
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      boxSizing: 'border-box',
      borderRadius: 2,
      cursor: 'text',
      background: isFocused ? COLORS.WHITE : 'rgba(255, 255, 255, 0.108)',
      boxShadow: isFocused
        ? `0px 2px 4px 0px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.15)`
        : '0 0 0 1px rgba(255, 255, 255, 0.2)',
      padding: `6px 12px 6px ${currentInputIsTextOnly ? 12 : 7}px`,
    }),
    icons: css({
      opacity: isEnabled ? 1 : 0.3,
    }),
  };

  return (
    <OutsideAlerter onClickedOutside={handleHide}>
      <div css={styles.base} ref={measureRef} onMouseDown={handleRootClick}>
        <QueryBuilderInput
          ref={inputRef}
          entities={data.entities}
          state={inputState}
          isEnabled={isEnabled}
          onChange={handleInputChange}
          isVisuallyFocused={isFocused}
          isFocused={isFocused && focusTarget === 'INPUT'}
          onFocus={handleInputFocus}
          onMouseDown={handleInputClick}
          onDropdownLocationChange={handleCursorLocationChange}
          onFormatChip={onFormatChip}
          onShowDropdown={onChipRequestDropdown}
          onChipStartedEditing={onChipStartedEditing}
          onChipStoppedEditing={onChipStoppedEditing}
        />
        <DropdownContainer
          data={data}
          maxWidth={bounds?.width}
          left={dropdownPosition.left}
          top={dropdownPosition.top}
        />
        <div css={styles.icons}>
          <IconButtons
            isFocused={isFocused || false}
            isStateEmpty={isStateEmpty}
            onClearClick={onClearClick}
          />
        </div>
      </div>
    </OutsideAlerter>
  );
};

export const QueryBuilder = forwardRef(View);
