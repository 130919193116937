/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormAutomatedActionConfiguration } from '@se/data/forms/types.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { useEmitDebounce } from '@seeeverything/ui.primitives/src/hooks/useEmitDebounce.ts';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useCallback } from 'react';
import { automatedActionConfigurationSlice } from '../../../redux/automatedActionConfiguration/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

export interface IAutomationActionDescriptionCellProps {
  row: IGridRow<FormAutomatedActionConfiguration>;
}

const View: React.FC<IAutomationActionDescriptionCellProps> = ({ row }) => {
  const dispatch = useFormsDispatch();
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

  const editing = useFormsSelector((state) =>
    Boolean(state.automatedActionConfiguration.editingRows[row.id]),
  );

  const saving = useFormsSelector((state) =>
    Boolean(state.automatedActionConfiguration.savingRows[row.id]),
  );

  const error = useFormsSelector(
    (state) => state.automatedActionConfiguration.errorRows[row.id],
  );

  const handleSaveChanges = useCallback(
    (to: string, isDone = false) => {
      if (error) return;
      dispatch(
        automatedActionConfigurationSlice.saveAutomatedAction({
          gridRowId: row.id,
          description: to,
          isDone,
        }),
      );
    },
    [dispatch, error, row.id],
  );

  const [statefulValue, setStatefulValue] = useEmitDebounce({
    value: row.value.description,
    delay: 5000,
    onDebounce: handleSaveChanges,
  });

  const handleUpdate = useCallback(
    (to: string) => {
      dispatch(
        automatedActionConfigurationSlice.clearRowError({ gridRowId: row.id }),
      );
      setStatefulValue(to);
    },
    [dispatch, row.id, setStatefulValue],
  );

  const handleStartEditing = useCallback(() => {
    dispatch(automatedActionConfigurationSlice.editRow({ gridRowId: row.id }));
  }, [dispatch, row.id]);

  const handleCancelEditing = useCallback(async () => {
    handleMouseLeave();

    if (error) return;

    /**
     * Jankfix: setTimeout is used to delay collapsing this row, to allow
     * another row to gain mouse click/focus (if clicking a row below the current one).
     */
    setTimeout(async () => {
      handleSaveChanges(statefulValue, true);
    }, 125);
  }, [handleMouseLeave, error, handleSaveChanges, statefulValue]);

  const elEditing = (
    <TextField
      id={row.id}
      placeholder={
        'Smart action, e.g. Please assess / confirm / update / upload the...'
      }
      value={statefulValue}
      onChange={handleUpdate}
      multiline={true}
      error={error ? `Unable to save. ${error}` : undefined}
      InputProps={{
        onFocus: (e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          ),
        onBlur: handleCancelEditing,
        type: 'text',
        autoComplete: 'off',
        autoFocus: true,
        style: {
          fontSize: 14,
          color: color.format(-0.7),
          whiteSpace: 'wrap',
          maxWidth: '100%',
          height: '100%',
        },
        startAdornment: (
          <Icons.edit
            fill={editing ? COLORS.BLUE : color.format(-0.25)}
            style={styles.inputAdornment}
          />
        ),
      }}
    />
  );

  const displayValue = statefulValue ?? row.value.description;

  const elEditButton = !editing && (
    <div
      css={styles.button}
      onClick={saving ? undefined : handleStartEditing}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <>
        {displayValue ? (
          <div
            css={
              saving
                ? [styles.description, styles.descriptionSaving]
                : styles.description
            }
          >
            <Icons.edit fill={COLORS.BLUE} />
            <Text
              color={saving ? color.format(-0.3) : color.format(-0.7)}
              size={14}
              ellipsis={true}
              cursor={'inherit'}
            >
              {displayValue}
            </Text>
          </div>
        ) : (
          <div
            css={
              isMouseOver
                ? [styles.addIcon, styles.addIconVisible]
                : [styles.addIcon]
            }
          >
            {elEditing}
          </div>
        )}
      </>
    </div>
  );

  return (
    <div css={styles.base}>
      {editing && elEditing}
      {elEditButton}
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 0px',
    minHeight: 37,
    position: 'relative',
  }),
  button: css({
    flex: '1 1 auto',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  }),
  addIcon: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    visibility: 'hidden',
    alignItems: 'center',
    flex: '1 1 auto',
  }),
  addIconVisible: css({
    visibility: 'visible',
  }),
  inputAdornment: {
    paddingRight: 10,
  },
  description: css({
    display: 'flex',
    gap: 8,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    ':hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  }),
  descriptionSaving: css({
    ':hover': {
      textDecoration: 'none',
    },
  }),
};

export const AutomatedActionsGridDescriptionCell = memo(
  View,
  propsAreEqualFastCompare,
);
