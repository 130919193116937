import { value } from '@seeeverything/ui.util/src/value/index.ts';
import * as R from 'ramda';
import { QueryBuilderDropdownAction, QueryState } from './types.ts';

export function reducer(state: QueryState, action: QueryBuilderDropdownAction) {
  switch (action.type) {
    case 'ui.shell/query/DROPDOWN_SHOW': {
      const isLoading = value.isPromise(action.payload.dropdown.props);
      const dropdown = { ...action.payload.dropdown, isLoading };

      if (isLoading) {
        delete dropdown.props; // Only include loaded dropdown-data. Promise is still loading.
      }
      state = { ...state, dropdown };
      return state;
    }

    case 'ui.shell/query/DROPDOWN_HIDE':
      state = { ...state };
      delete state.dropdown;
      return state;

    case 'ui.shell/query/DROPDOWN_LOADED':
      if (action.payload.data.column === undefined) {
        state = { ...state };
        delete state.dropdown;
        return state;
      }

      state = {
        ...state,
        dropdown: state.dropdown && {
          ...state.dropdown,
          isLoading: false,
          props: action.payload.data,
          height: action.payload.data.height
            ? action.payload.data.height
            : state.dropdown.height,
        },
      };
      return state;

    case 'ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED':
      state = {
        ...state,
        dropdown: state.dropdown && {
          ...state.dropdown,
          isLoading: true, // Loading as the reloading will be done by the epics.
        },
        activeInlineFilters: toggleInlineFilterActiveState(
          state.activeInlineFilters ?? [],
          action.payload.id,
        ),
      };

      return state;

    default:
      return state;
  }
}

/**
 * Adds or removes an inline filter ID from the list of active filter IDs.
 */
const toggleInlineFilterActiveState = (
  activeInlineFilterIds: string[],
  clickedFilterId: string,
) => {
  return activeInlineFilterIds.includes(clickedFilterId)
    ? R.without([clickedFilterId], activeInlineFilterIds)
    : [...activeInlineFilterIds, clickedFilterId];
};
