/** @jsxImportSource @emotion/react */
import { DashboardColumnFilter } from '@se/data/dashboards/query/gridFilters.ts';
import { IDataGridProps } from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { GridClickEvent } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { useCallback } from 'react';
import {
  dashboardGridsSlice,
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../../redux/index.ts';
import { FilterPopupContainer } from './FilterPopupContainer.tsx';

export interface IDashboardGridFilteringBehaviorProps {
  gridKey: string;
  dashboardType: 'GRID' | 'CLICK_THROUGH_GRID';
  sheetType: 'DASHBOARD' | 'FULL_SHEET';
}

export const DashboardGridFilteringBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridProps & IDashboardGridFilteringBehaviorProps> =>
  function FilterSortableDataGrid({
    id,
    gridKey,
    columns,
    dashboardType,
    sheetType,
    ...rest
  }) {
    const { track } = useSegmentAnalytics();
    const dispatch = useDashboardsDispatch();
    const filtersEnabled = useDashboardsSelector(
      (state) => state.tenantState.tenant.features.DASHBOARDS.TEMP_FILTERS,
    );
    const columnSorts = useDashboardsSelector(
      (state) => state.dashboardGrids.sort[gridKey],
    );

    const columnFilters = useDashboardsSelector(
      (state) => state.dashboardGrids.columnFilters[gridKey],
    );

    const showPopupForColumnId = useDashboardsSelector(
      (state) => state.dashboardGrids.showPopupForColumnId[gridKey],
    );

    const handleColumnFilterSelection = useCallback(
      (columnId: string, filter: DashboardColumnFilter, to: boolean) => {
        track('dashboard_grid_filter', {
          sectionId: id,
          column: columnId,
          filter: filter.displayValue,
          dashboardType,
          gridKey,
          sheetType,
          include: to,
        });

        dispatch(
          dashboardGridsSlice.updateColumnFilterSelection({
            gridKey,
            columnId,
            filter,
            to,
          }),
        );
      },
      [dashboardType, dispatch, gridKey, id, sheetType, track],
    );

    const handleColumnSort = useCallback(
      (columnId: string, direction: 'ASC' | 'DESC') => {
        const orderBy = {
          columnId,
          direction: direction === 'ASC' ? 'Ascending' : 'Descending',
        } as const;

        track('dashboard_grid_sort', {
          sectionId: id,
          column: columnId,
          direction: orderBy.direction,
          dashboardType,
          gridKey,
          sheetType,
        });

        dispatch(dashboardGridsSlice.sortGrid({ gridKey, orderBy }));
      },
      [dashboardType, dispatch, gridKey, id, sheetType, track],
    );

    const handleHeaderClick = useCallback(
      (e: GridClickEvent) => {
        if (!e.isLastRow) return;

        if (showPopupForColumnId === e.columnId) return;

        const columnId = e.columnId;
        const column = columns.find((c) => c.id === e.columnId);
        const isFilterable = Boolean(filtersEnabled && column.isFilterable);
        const isSortable = column.isSortable ?? true;

        if (isFilterable) {
          dispatch(
            dashboardGridsSlice.showColumnPopup({
              gridId: id,
              gridKey,
              columnId,
            }),
          );
          return;
        }

        if (!isSortable) return;

        const currentSort = columnSorts?.orderBys?.find(
          (o) => o.columnId === columnId,
        )?.direction;
        const nextSort = currentSort === 'Descending' ? 'ASC' : 'DESC';

        handleColumnSort(column.id, nextSort);
      },
      [
        showPopupForColumnId,
        columns,
        filtersEnabled,
        columnSorts?.orderBys,
        handleColumnSort,
        dispatch,
        id,
        gridKey,
      ],
    );

    const handleRenderHeaderPopup = useCallback(
      (columnId: string) => {
        if (!showPopupForColumnId) return;
        if (showPopupForColumnId !== columnId) return;

        const column = columns.find((c) => c.id === columnId);
        if (!column) return;

        return (
          <FilterPopupContainer
            gridKey={gridKey}
            column={column}
            gridId={id}
            onColumnFilterSelection={handleColumnFilterSelection}
            onColumnSort={handleColumnSort}
            onHidePopup={() =>
              dispatch(
                dashboardGridsSlice.hideColumnPopup({
                  gridKey,
                  columnId: column.id,
                }),
              )
            }
            showPopupForColumnId={showPopupForColumnId}
          />
        );
      },
      [
        columns,
        dispatch,
        gridKey,
        handleColumnFilterSelection,
        handleColumnSort,
        id,
        showPopupForColumnId,
      ],
    );

    const getHeaderIcon = useCallback(
      (columnId: string) => {
        const filters = columnFilters?.[columnId];
        const selectedFilters = filters?.selectedFilters ?? [];
        const sort = columnSorts?.orderBys?.find(
          (o) => o.columnId === columnId,
        )?.direction;

        const isFiltered = selectedFilters.length > 0;

        if (!isFiltered && !sort) return;

        if (isFiltered && sort) return Icons.viewHeadline;
        if (isFiltered) return Icons.filter;

        return sort === 'Ascending'
          ? Icons.sortAscending
          : Icons.sortDescending;
      },
      [columnFilters, columnSorts],
    );

    return (
      <DataGrid
        {...rest}
        id={id}
        onClick={() => void 0}
        columns={columns}
        onHeaderClick={handleHeaderClick}
        getHeaderIcon={getHeaderIcon}
        renderHeaderPopup={handleRenderHeaderPopup}
      />
    );
  };
