import {
  IRecursiveFetchAllOptions,
  recursiveFetchAll,
} from '@seeeverything/ui.util/src/graphql/graphql/recursiveFetchAll.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import moment from 'moment';
import { IDesignerDraft } from '../model/types.ts';

export async function drafts(
  client: IGraphQLClient,
  orderBy: OrderBy,
  templateId?: string,
): Promise<IDesignerDraft[]> {
  const fetchOptions: IRecursiveFetchAllOptions = {
    client,
    query: gql`
      query DesignerDrafts(
        $categoryId: String
        $name: String
        $orderBy: [OrderByInput!]
        $templateId: String
        $pageNumber: Int
        $pageSize: Int
      ) {
        forms {
          formsDesignerDrafts(
            categoryId: $categoryId
            name: $name
            orderBy: $orderBy
            pagination: { pageNumber: $pageNumber, size: $pageSize }
            templateId: $templateId
          ) {
            pageInfo {
              hasNextPage
              currentPage
            }
            nodes {
              id
              name
              category {
                id
                name
              }
              publishedTemplateId
              status
              createdAt
              createdBy {
                id
                firstName
                lastName
              }
              updatedAt
              updatedBy {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    `,
    pageSize: 100,
    variables: {
      orderBy,
      templateId,
    },
  };

  try {
    const responsePages = await recursiveFetchAll(
      fetchOptions,
      resolveHasNextPage,
      1,
      'network-only',
    );

    const designerDrafts = responsePages.reduce<FormsDesignerDraft[]>(
      (acc, val) =>
        val ? acc.concat(val.forms.formsDesignerDrafts.nodes) : acc,
      [],
    );

    return designerDrafts.map(
      ({
        id,
        name,
        category,
        status,
        publishedTemplateId,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
      }) => ({
        id,
        name,
        category: category?.name || '-',
        status,
        publishedTemplateId,
        createdAt: moment(createdAt).format('D MMM YYYY'),
        createdBy: personName(createdBy),
        updatedAt: moment(updatedAt).format('D MMM YYYY'),
        updatedBy: personName(updatedBy),
      }),
    ) as IDesignerDraft[];
  } catch (error) {
    log.error(new Error(`Unable to query designer drafts - ${error.message}`));
    throw error;
  }
}

const personName = (person?: { firstName: string; lastName: string }) =>
  person && joinName(person.firstName, person.lastName);
const joinName = (...parts: string[]) => parts.filter(Boolean).join(' ');

const resolveHasNextPage = (response: DesignerDraftsResponse) =>
  Boolean(response?.forms.formsDesignerDrafts.pageInfo?.hasNextPage);

type FormsDesignerDraft = {
  id: string;
  name: string;
  category: { id: string; name: string };
  publishedTemplateId: string;
  status: string;
  createdAt: Date;
  createdBy: { id: string; firstName: string; lastName: string };
  updatedAt: Date;
  updatedBy: { firstName: string; lastName: string };
};

type DesignerDraftsResponse = {
  forms: {
    formsDesignerDrafts: {
      pageInfo: {
        hasNextPage: boolean;
        currentPage: number;
      };
      nodes: FormsDesignerDraft[];
    };
  };
};
