/** @jsxImportSource @emotion/react */
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  takeUntil,
} from 'rxjs';
import {
  IQuery,
  IQueryData,
  IQuerySelection,
} from '../../api/api.queryBuilder/types.ts';
import * as actions from '../../redux/query/actions.ts';
import { QueryState } from '../../redux/query/types.ts';
import { ShellAction, ShellState } from '../../redux/types.ts';
import {
  IQueryBuilderRefProps,
  QueryBuilder,
  QueryBuilderEvent,
  QueryBuilderFocusEvent,
} from './QueryBuilder.tsx';
import { deserialize } from './common/serialization.ts';
import {
  ChipEditEvent,
  FormatChipEvent,
} from './components/QueryBuilderInput/index.ts';
import { IInternalState } from './types.ts';

export interface IQueryBuilderContainerProps {
  data: IQueryData;
}

interface IViewProps extends IQueryBuilderContainerProps {
  queryState: QueryState;
  dispatch: (action: ShellAction) => void;
}
interface IViewState {
  internalState: IInternalState;
}

/**
 * The shell wrapper of the QueryBuilder
 */
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    internalState: this.emptyState,
  };

  private queryBuilder: IQueryBuilderRefProps;
  private queryBuilderRef = (ref: any) => (this.queryBuilder = ref);

  private unmounted$ = new Subject<void>();
  private queryBuilderFocusChanged$ = new Subject<QueryBuilderFocusEvent>();
  private queryBuilderChanged$ = new Subject<QueryBuilderEvent>();
  private props$ = new Subject<IViewProps>();

  private get emptyState() {
    return this.deserialize();
  }
  private deserialize(query?: IQuery, selection?: IQuerySelection) {
    return deserialize({
      entities: this.props.data.entities,
      query,
      selection,
    });
  }

  public shouldComponentUpdate(nextProps: IViewProps, nextState: IViewState) {
    return !R.equals(this.props, nextProps) || !R.equals(this.state, nextState);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }
  public UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));

    const queryBuilderChanged$ = merge(
      this.queryBuilderChanged$
        // QB change events that fire only when the Query and Selection values change.
        .pipe(
          takeUntil(this.unmounted$),
          distinctUntilChanged((prev, next) => {
            return (
              R.equals(prev.query, next.query) &&
              R.equals(prev.selection, next.selection)
            );
          }),
        ),
      // Also include any events that have `forceRefresh` enforced.
      this.queryBuilderChanged$.pipe(filter((e) => e.forceRefresh === true)),
    );

    this.queryBuilderFocusChanged$
      .pipe(
        // QueryBuilder `onFocus` and `onBlur` event.
        takeUntil(this.unmounted$),
        debounceTime(0), // NB: Even out the focus change events that fire true/false noisily.
      )
      .subscribe((e) => {
        if (this.props.queryState.focus !== e.focusTarget) {
          const isFocused = e.focusTarget !== undefined;
          const action = isFocused
            ? actions.focus(e.focusTarget)
            : actions.blur();
          dispatch(action);
        }
      });

    props$
      .pipe(
        // Update focus on property change.
        map((e) => e.queryState),
        distinctUntilChanged((prev, next) => prev.focus === next.focus),
      )
      .subscribe((e) => {
        if (e.focus) {
          this.queryBuilder.focus();
        } else {
          this.queryBuilder.blur();
        }
      });

    /**
     * Apply a debounce here for editing chips. i.e. Don't run a query for every
     * keystroke on a single chip.
     */
    merge(
      queryBuilderChanged$.pipe(
        // Fire `NEXT` redux action when QueryBuilder changes.
        filter((e) =>
          Boolean(
            !e.isCancelled &&
              e.selection &&
              e.selection.type !== 'DROPDOWN' &&
              e.selection.chip.label !== undefined,
          ),
        ),
        debounceTime(250),
      ),
      // Don't debounce for these.
      queryBuilderChanged$
        // Fire `NEXT` redux action when QueryBuilder changes.
        .pipe(
          filter((e) =>
            Boolean(
              !e.isCancelled &&
                (!e.selection ||
                  (e.selection.type === 'DROPDOWN' &&
                    e.selection.chip.label === undefined)),
            ),
          ),
        ),
    ).subscribe((e) => {
      dispatch(actions.next('INPUT', e.query, e.selection));
    });

    const nextQuery$ = props$.pipe(
      // Create clean stream of query changes.
      map((e) => e.queryState),
      distinctUntilChanged((prev, next) => {
        return (
          R.equals(prev.query, next.query) &&
          R.equals(prev.selection, next.selection) &&
          prev.focus === next.focus
        );
      }),
    );

    nextQuery$
      .pipe(
        // Ensure the QueryBuilder has the latest query (when changed externally by `code`).
        filter((e) => e.changeSource === 'CODE'),
      )
      .subscribe((e) => {
        this.setState({
          internalState: this.deserialize(e.query, e.selection),
        });
      });
  }

  public componentDidMount() {
    this.props$.next(this.props);
  }
  public UNSAFE_componentWillReceiveProps(nextProps: IViewProps) {
    this.props$.next(nextProps);
  }

  public render() {
    return (
      <QueryBuilder
        ref={this.queryBuilderRef}
        state={this.state.internalState}
        isEnabled={this.props.queryState.isEnabled}
        data={this.props.data}
        onFocusChanged={this.observableHandler(this.queryBuilderFocusChanged$)}
        onChanged={this.observableHandler(this.queryBuilderChanged$)}
        onClearClick={this.handleClearClick}
        onFormatChip={this.handleFormatChip}
        onChipStartedEditing={this.handleChipStartedEditing}
        onChipStoppedEditing={this.handleChipStoppedEditing}
      />
    );
  }

  private observableHandler = (subject$: Subject<any>) => (e: any) =>
    subject$.next(e);

  private handleClearClick = () => {
    setTimeout(() => {
      this.props.dispatch(
        actions.next('CODE', {
          chips: [],
          filter: '',
        }),
      );
    }, 0);
  };

  private handleFormatChip = (e: FormatChipEvent) => {
    const { data, queryState } = this.props;
    const { index, chip } = e;
    const { query } = queryState;
    return data.formatChip({ index, chip, query });
  };

  private handleChipStartedEditing = (e: ChipEditEvent) => {
    const { dispatch } = this.props;
    dispatch(actions.chipStartedEditing(e.index, e.chip));
  };

  private handleChipStoppedEditing = (e: ChipEditEvent) => {
    const { data, dispatch, queryState } = this.props;
    const { query, selection } = queryState;

    // Ask the business-logic to evaluate the query for auto-completion.
    const response = data.change({
      action: 'AUTOCOMPLETE',
      query,
      selection,
      context: {},
    });
    const isCancelled = response?.isCancelled;
    const newQuery = response?.query;

    if (newQuery) {
      // The business logic passed back a new query.
      // Load this into the query-builder.
      dispatch(actions.next('CODE', newQuery));
    }

    if (!newQuery && isCancelled) {
      // The business logic cancelled the edit.
      // Cancel the edit, which will revert to the prior state.
      e.cancel();
    }
    if (!isCancelled) {
      // Business logic did not cancel the edit.
      // Fire 'stopped editing' action as an alert.
      dispatch(actions.chipStoppedEditing(e.index, e.chip));
    }
  };
}

const mapStateToProps = (state: ShellState) => ({
  queryState: state.query,
});
const mapDispatchToProps = (dispatch: (action: ShellAction) => void) => ({
  dispatch,
});
export const QueryBuilderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
