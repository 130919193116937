/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  DataGrid,
  IDataGridProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/DataGrid.tsx';
import {
  DataGridInfiniteScrollBehavior,
  IDataGridInfiniteScrollBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/DataGridInfiniteScrollBehavior.tsx';
import { DataGridIsLoadingBehavior } from '@seeeverything/ui.primitives/src/components/DataGrid/DataGridIsLoadingBehavior.tsx';
import {
  DataGridStatusToggleSortableBehavior,
  IDataGridStatusToggleSortableBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/DataGridStatusToggleSortableBehavior.tsx';
import {
  DataGridFilter,
  IDataGridColumn,
} from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import {
  GridClickEvent,
  IGridRow,
} from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { Overlay } from '@seeeverything/ui.primitives/src/components/Overlay/Overlay.tsx';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { editGoalScheduleSlice } from '../../redux/editGoalSchedule/index.ts';
import { goalScheduleSlice } from '../../redux/goalSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { GoalScheduleEditPanelContainer } from './components/GoalScheduleEditPanelContainer.tsx';

const createGridColumns = (hasGoalCategories: boolean): IDataGridColumn[] =>
  [
    {
      id: 'createdAt',
      label: 'Created',
      width: 100,
      isFilterable: false,
      isSortable: true,
    },
    hasGoalCategories && {
      id: 'goalCategory',
      label: 'Goal Category',
      width: '*',
      isFilterable: false,
      isSortable: false,
    },
    {
      id: 'goalDescription',
      label: 'Description',
      width: '3*',
      isFilterable: false,
      isSortable: true,
    },
    {
      id: 'distributionList',
      label: 'Distribution List',
      width: '*',
      isFilterable: false,
      isSortable: false,
    },
    {
      id: 'startDate',
      label: 'Start Date',
      width: 100,
      isFilterable: false,
      isSortable: true,
    },
    {
      id: 'status',
      label: 'Status',
      width: 100,
      isFilterable: true,
      isSortable: false,
      filterToggles: [
        {
          id: 'FILTER_SHOW_INACTIVE',
          columnId: 'status',
          label: 'Include Inactive',
          isToggled: false,
        },
      ],
    },
    {
      id: 'lastUpdated',
      label: 'Last Updated By',
      width: 200,
      isFilterable: false,
      isSortable: false,
    },
  ].filter(Boolean);

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridProps
>;

export const GoalScheduleGridContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const momentTenantTimezone = useMomentTenantTimezone();

  const goalSchedules = useFormsSelector(
    (state) => state.goalSchedule.schedules,
  );
  const showAll = useFormsSelector((state) => state.goalSchedule.showAll);
  const orderBy = useFormsSelector((state) => state.goalSchedule.orderBy);
  const draft = useFormsSelector((state) => state.editGoalSchedule.draft);
  const hasNextPage = useFormsSelector(
    (state) => state.goalSchedule.hasNextPage,
  );
  const loadedPage = useFormsSelector(
    (state) => state.goalSchedule.currentPage,
  );
  const isLoading = useFormsSelector((state) => state.goalSchedule.isLoading);
  const isLoadingMore = useFormsSelector(
    (state) => state.goalSchedule.isLoadingMore,
  );
  const hasGoalCategories = useFormsSelector(
    (state) => state.tenantState.tenant.features?.FORMS.GOAL_CATEGORIES,
  );

  const tenantGoalAdditionalFields = useFormsSelector(
    (state) =>
      state.tenantState.tenant.configuration.goalAdditionalFields ?? [],
  );

  const filters = useMemo<DataGridFilter[]>(
    () => [
      {
        columnId: orderBy.fieldName,
        sort: orderBy.direction === 'Ascending' ? 'ASC' : 'DESC',
      },
      {
        columnId: 'status',
        toggles: [
          {
            id: 'FILTER_SHOW_INACTIVE',
            columnId: 'status',
            label: 'Show Inactive',
            isToggled: showAll,
          },
        ],
      },
    ],
    [showAll, orderBy],
  );

  const columns = useMemo(
    () => createGridColumns(hasGoalCategories),
    [hasGoalCategories],
  );

  const rowData = useMemo<IGridRow[]>(
    () =>
      goalSchedules.map((goalSchedule) => {
        return {
          id: goalSchedule.id,
          data: hasGoalCategories
            ? [
                momentTenantTimezone(goalSchedule.createdAt).format('D MMM YY'),
                goalSchedule.goalCategory?.name ?? '-',
                goalSchedule.goalDescription,
                goalSchedule.distributionList.name,
                momentTenantTimezone(goalSchedule.startDate).format('D MMM YY'),
                str.humanize(goalSchedule.status, true),
                goalSchedule.updatedBy.name,
              ]
            : [
                momentTenantTimezone(goalSchedule.createdAt).format('D MMM YY'),
                goalSchedule.goalDescription,
                goalSchedule.distributionList.name,
                momentTenantTimezone(goalSchedule.startDate).format('D MMM YY'),
                str.humanize(goalSchedule.status, true),
                goalSchedule.updatedBy.name,
              ],
        };
      }),
    [goalSchedules, momentTenantTimezone, hasGoalCategories],
  );

  const handleLoadPage = useCallback(() => {
    dispatch(goalScheduleSlice.loadGoalSchedules({ loadNextPage: true }));
  }, [dispatch]);

  const handleConfirmDismissChanges = useCallback(() => {
    dispatch(editGoalScheduleSlice.alertConfirmDismissChanges());
  }, [dispatch]);

  const handleColumnSort = useCallback(
    (
      columnId: 'createdAt' | 'goalDescription' | 'startDate',
      direction: 'ASC' | 'DESC',
    ) => {
      dispatch(
        goalScheduleSlice.sortBy({
          fieldName: columnId,
          direction: direction === 'ASC' ? 'Ascending' : 'Descending',
        }),
      );
    },
    [dispatch],
  );

  const handleToggleShowInactive = useCallback(
    (columnId: string, toggleId: string, to: boolean) => {
      if (columnId !== 'status') return;
      if (toggleId !== 'FILTER_SHOW_INACTIVE') return;
      dispatch(goalScheduleSlice.toggleShowAll({ to }));
    },
    [dispatch],
  );

  const handleRowClick = useCallback(
    (e: GridClickEvent) => {
      const schedule = goalSchedules.find(
        ({ id }) => id === e.rowId.toString(),
      );
      if (!schedule) return;

      dispatch(
        editGoalScheduleSlice.editSchedule({
          schedule,
          tenantGoalAdditionalFields,
        }),
      );
    },
    [dispatch, goalSchedules, tenantGoalAdditionalFields],
  );

  const elOverlay = draft && (
    <OutsideAlerter
      onClickedOutside={handleConfirmDismissChanges}
      style={styles.outsideAlerter}
    >
      <GoalScheduleEditPanelContainer />
    </OutsideAlerter>
  );

  return (
    <Overlay overlay={elOverlay} isVisible={Boolean(draft)}>
      <Grid
        id={'GoalSchedules'}
        data={rowData}
        columns={columns}
        onColumnStatusToggled={handleToggleShowInactive}
        onColumnSort={handleColumnSort}
        filters={filters}
        onClick={handleRowClick}
        isScrollable={true}
        isVirtualized={true}
        hasNextPage={hasNextPage}
        loadedPage={loadedPage}
        isLoading={isLoading}
        isLoadingPage={isLoadingMore}
        onLoadPage={handleLoadPage}
      />
    </Overlay>
  );
};

const styles = {
  outsideAlerter: css({
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
  }),
};
