/** @jsxImportSource @emotion/react */
import { BulkUploadJobsGrid } from '@seeeverything/ui.forms/src/components/BulkUploadJobsGrid/BulkUploadJobsGrid.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useMemo } from 'react';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetFormsBulkUploadHistoryContainer: React.FC = () => {
  const formLabel = useTenantLocale((l) => l.label.form);

  const title = useMemo(() => `${formLabel} Loader`, [formLabel]);

  return (
    <SheetLayout
      title={title}
      leftTools={[
        {
          id: 'DOWNLOAD_FORM_TEMPLATE',
          icon: Icons.fileDownload,
          label: 'Download Template',
        },
        {
          id: 'IMPORT_BULK_UPLOAD_FILE',
          icon: Icons.publish,
          label: `Upload ${str.plural(formLabel)}`,
        },
      ]}
      rightTools={[
        {
          id: 'IMPORT_BULK_UPLOAD_REFRESH',
          icon: Icons.refresh,
          label: 'Refresh List',
        },
      ]}
    >
      <BulkUploadJobsGrid />
    </SheetLayout>
  );
};
